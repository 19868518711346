import React from 'react';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/response/VehicleSearchHistoryResponse';
import { DataContainer } from 'components/DataContainer';
import HomePageCategories from 'components/HomePageCategories';
import Loader from 'components/Loader';
import PromotionBannerWrapper from 'components/PromotionBanner';
import SearchHistoryOverview from 'components/SearchHistoryOverview';
import { PromotionBannerCarousel } from 'domains/promotion/Promotion.types';
import { PortfolioCard, PortfolioCardProps } from 'pages/HomePage/HomePage';
import SearchBar from 'pages/HomePage/search/SearchBar';
import CurrentPromotionsPageBanner from 'pages/PromotionPage/CurrentPromotionsPageBanner';
import { Carousel, Container, Grid, MarginBox } from 'UI';
import { isLoaded, NO_DATA } from 'utils';

export const HomepageLg = ({
  vehicleSearchHistory,
  banners,
  bannersWithImage,
  portfolioData,
}: {
  vehicleSearchHistory: VehicleDetail[] | NO_DATA;
  banners: NO_DATA | PromotionBannerCarousel[];
  bannersWithImage: PromotionBannerCarousel[];
  portfolioData: Array<PortfolioCardProps>;
}) => {
  return (
    <Container size={'xxl'}>
      <MarginBox mt={30} />
      <SearchBar />
      <MarginBox mt={30} />
      <SearchHistoryOverview redirectToCatalog={true} />
      {isLoaded(vehicleSearchHistory) && (
        <>
          <MarginBox mt={30} />
          <DataContainer data={banners} Loading={() => <Loader height={'400px'} />} NotFound={() => <></>}>
            <MarginBox mt={15} />
            {bannersWithImage.length > 0 && (
              <Carousel>
                {bannersWithImage.map((banner) => (
                  <PromotionBannerWrapper key={banner.promotionId} banner={banner} />
                ))}
              </Carousel>
            )}
            <MarginBox mt={30} />
            <CurrentPromotionsPageBanner homepage />
            <MarginBox mt={30} />
          </DataContainer>
          <HomePageCategories />
          <Grid
            columns={[1, 2]}
            gutter={[
              [30, 30],
              [30, 30],
            ]}
          >
            {portfolioData.map((data, i) => (
              <PortfolioCard key={`portfolio-${i}`} {...data} />
            ))}
          </Grid>
          <MarginBox mt={30} />
        </>
      )}
    </Container>
  );
};

/* eslint-disable max-len */
import React, { PropsWithChildren, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  AngleLeftIcon,
  AngleRightIcon,
  ArchiveIcon,
  ArrowClockIcon,
  CarIcon,
  CartArrowDownIcon,
  CheckIcon,
  ClockFullIcon,
  DMSExportIcon,
  EnvelopeIcon,
  ExclamationTriangleIcon,
  FileAltIcon,
  FolderOpenIcon,
  ImageIcon,
  InfoCircleIcon,
  LinkIcon,
  MinusCircleIcon,
  MinusIcon,
  PencilAltIcon,
  PlusCircleIcon,
  PlusIcon,
  PrintIcon,
  TagIcon,
  TagsIcon,
  ThumbsUpIcon,
  TrashAltIcon,
  TrashIcon,
  UploadIcon,
} from 'assets/icons';
import { ThemeColorKeyType } from 'styles';
import { Box, CenterFlex, Flex, IconType, MarginBox, WithTooltip } from 'UI';
import { hasData, useNonce } from 'utils';
import { WithLink } from '../Link';
import { Text } from '../Text';

// Specific icon buttons
export const SearchHistoryButton = (props: BaseIconButtonProps) => (
  <IconButton IconComponent={ArrowClockIcon} color={'ink_black'} bgColor={'grey95'} size={20} {...props} />
);
export const CarButton = (props: BaseIconButtonProps) => (
  <IconButton IconComponent={CarIcon} color={'ink_black'} bgColor={'grey95'} size={28} {...props} />
);
export const PlusCircleButton = (props: BaseIconButtonProps) => (
  <IconButton IconComponent={PlusCircleIcon} color={'clear_blue'} bgColor={'transparent'} size={24} {...props} />
);
export const MinusCircleButton = (props: BaseIconButtonProps) => (
  <IconButton IconComponent={MinusCircleIcon} color={'clear_blue'} bgColor={'transparent'} size={24} {...props} />
);
export const PlusButton = (props: BaseIconButtonProps) => (
  <IconButton
    IconComponent={PlusIcon}
    size={16}
    bgColor={'brand'}
    color={'brand_black'}
    disabledColor={'grey75'}
    {...props}
  />
);
export const MinusButton = (props: BaseIconButtonProps) => (
  <IconButton IconComponent={MinusIcon} size={16} color={'white'} {...props} />
);
export const DeleteButton = (props: BaseIconButtonProps) => (
  <IconButton IconComponent={TrashIcon} size={24} color={'brand_black'} bgColor={'transparent'} {...props} />
);
export const DeleteAltButton = (props: BaseIconButtonProps) => (
  <IconButton IconComponent={TrashIcon} size={16} color={'white'} bgColor={'grey55'} {...props} />
);
export const TrashAltButton = (props: BaseIconButtonProps) => {
  const { t } = useTranslation();
  return (
    <IconButton
      IconComponent={TrashAltIcon}
      size={16}
      round
      color={'brand_black'}
      bgColor={'grey95'}
      tooltip={t('common.action.delete', 'Delete')}
      {...props}
    />
  );
};
export const TrashAltSquareButton = (props: BaseIconButtonProps) => (
  <IconButton IconComponent={TrashAltIcon} size={16} color={'white'} bgColor={'grey55'} {...props} />
);
export const DeleteRoundButton = (props: BaseIconButtonProps) => (
  <IconButton IconComponent={TrashIcon} size={16} round color={'brand_black'} {...props} />
);
export const PencilRoundButton = (props: BaseIconButtonProps) => {
  const { t } = useTranslation();
  return (
    <IconButton
      IconComponent={PencilAltIcon}
      size={16}
      round
      color={'brand_black'}
      tooltip={t('common.action.note', 'Note')}
      {...props}
    />
  );
};
export const LinkRoundButton = (props: BaseIconButtonProps) => (
  <IconButton
    IconComponent={LinkIcon}
    boxSize={'xsm'}
    size={12}
    round
    bgColor={'brand'}
    hoverFill={'brand'}
    {...props}
  />
);
export const ThumbsUpRoundButton = (props: BaseIconButtonProps) => {
  const { t } = useTranslation();
  return (
    <IconButton
      IconComponent={ThumbsUpIcon}
      boxSize={'xsm'}
      size={15}
      round
      bgColor={'brand'}
      hoverFill={'brand'}
      tooltip={t('catalog.reference_card.best_supersession', 'Best supersession')}
      {...props}
    />
  );
};
export const LinkButton = (props: BaseIconButtonProps) => (
  <IconButton
    IconComponent={ExclamationTriangleIcon}
    size={14}
    round
    bgColor={'clear_blue'}
    hoverFill={'clear_blue'}
    displayAsLink
    color={'white'}
    {...props}
  />
);
export const FileAltButton = (props: BaseIconButtonProps) => (
  <IconButton IconComponent={FileAltIcon} size={24} round {...props} />
);

export const ImageCircleButton = (props: BaseIconButtonProps) => (
  <IconButton IconComponent={ImageIcon} size={24} round {...props} />
);

export const InfoCircleButton = (props: BaseIconButtonProps) => (
  <IconButton IconComponent={InfoCircleIcon} size={24} round {...props} />
);
export const ClockButton = (props: BaseIconButtonProps) => (
  <IconButton IconComponent={ClockFullIcon} size={24} round {...props} />
);
export const CartArrowDownButton = (props: BaseIconButtonProps) => {
  const { t } = useTranslation();
  return (
    <IconButton
      IconComponent={CartArrowDownIcon}
      size={24}
      round
      tooltip={t('cart.action.add_to_cart', 'Add to cart')}
      {...props}
    />
  );
};
export const YellowCartArrowDownButton = (props: BaseIconButtonProps) => {
  const { t } = useTranslation();
  return (
    <IconButton
      IconComponent={CartArrowDownIcon}
      size={20}
      boxSize={'sm'}
      bgColor={'brand'}
      hoverFill={'brand_light_130'}
      tooltip={t('cart.action.add_to_cart', 'Add to cart')}
      {...props}
    />
  );
};
export const FolderOpenButton = (props: BaseIconButtonProps) => (
  <IconButton IconComponent={FolderOpenIcon} size={24} round {...props} />
);
export const CartArrowDownButtonRound = (props: BaseIconButtonProps) => {
  const { t } = useTranslation();
  return (
    <IconButton
      IconComponent={CartArrowDownIcon}
      round
      size={30}
      boxSize={'lg'}
      color={'grey15'}
      bgColor={'grey95'}
      hoverFill={'grey75'}
      tooltip={props.tooltip ?? t('cart.action.add_to_cart', 'Add to cart')}
      {...props}
    />
  );
};
export const DMSExportButtonRound = ({ dmsExportTime, ...props }: BaseIconButtonProps) => {
  return (
    <IconButton
      IconComponent={dmsExportTime ? CheckIcon : DMSExportIcon}
      round
      size={dmsExportTime ? 15 : 30}
      boxSize={'lg'}
      color={dmsExportTime ? 'grass_green' : 'grey95'}
      bgColor={dmsExportTime ? 'light_green' : 'grey95'}
      borderColor={dmsExportTime ? 'grass_green' : 'grey95'}
      hoverColor={dmsExportTime ? 'grass_green' : 'grey95'}
      hoverFill={dmsExportTime ? 'light_green' : 'grey95'}
      {...props}
    />
  );
};

export const MessageButtonRound = (props: BaseIconButtonProps) => {
  const { t } = useTranslation();
  return (
    <IconButton
      IconComponent={EnvelopeIcon}
      round
      size={30}
      boxSize={'lg'}
      color={'grey15'}
      bgColor={'grey95'}
      hoverFill={'grey75'}
      tooltip={t('common.action.send_by_email', 'Send by email')}
      {...props}
    />
  );
};

export const PrintButtonRound = (props: BaseIconButtonProps) => {
  const { t } = useTranslation();

  return (
    <IconButton
      IconComponent={PrintIcon}
      round
      size={30}
      boxSize={'lg'}
      color={'grey15'}
      bgColor={'grey95'}
      hoverFill={'grey75'}
      tooltip={t('common.action.print', 'Print')}
      {...props}
    />
  );
};

export const TagButtonRound = (props: BaseIconButtonProps) => {
  return (
    <IconButton
      IconComponent={TagIcon}
      round
      size={30}
      boxSize={'lg'}
      color={'grey15'}
      bgColor={'grey95'}
      hoverFill={'grey75'}
      {...props}
    />
  );
};

export const TagsButtonRound = (props: BaseIconButtonProps) => {
  return (
    <IconButton
      IconComponent={TagsIcon}
      round
      size={30}
      boxSize={'lg'}
      color={'grey15'}
      bgColor={'grey95'}
      hoverFill={'grey75'}
      {...props}
    />
  );
};

export const EstimateButtonRound = (props: BaseIconButtonProps) => {
  const { t } = useTranslation();

  return (
    <IconButton
      IconComponent={FileAltIcon}
      round
      size={30}
      boxSize={'lg'}
      color={'grey15'}
      bgColor={'grey95'}
      hoverFill={'grey75'}
      tooltip={t('catalog.action.add_to_estimate', 'Add to estimate')}
      {...props}
    />
  );
};

export const ArchiveButtonRound = (props: BaseIconButtonProps) => (
  <IconButton
    IconComponent={ArchiveIcon}
    round
    size={30}
    boxSize={'lg'}
    color={'grey15'}
    bgColor={'grey95'}
    hoverFill={'grey75'}
    {...props}
  />
);

export const ArrowLeftButtonRound = (props: BaseIconButtonProps) => (
  <IconButton
    IconComponent={AngleLeftIcon}
    round
    size={50}
    boxSize={'lg'}
    color={'grey55'}
    bgColor={'grey95'}
    hoverFill={'grey85'}
    disabledBgColor={'grey85'}
    {...props}
  />
);

export const ArrowRightButtonRound = (props: BaseIconButtonProps) => (
  <IconButton
    IconComponent={AngleRightIcon}
    round
    size={50}
    boxSize={'lg'}
    color={'grey55'}
    bgColor={'grey95'}
    hoverFill={'grey85'}
    disabledBgColor={'grey85'}
    {...props}
  />
);

export const FileButtonRound = (props: BaseIconButtonProps) => (
  <IconButton
    IconComponent={FileAltIcon}
    round
    size={16}
    boxSize={'sm'}
    color={'brand_black'}
    bgColor={'grey85'}
    hoverFill={'grey75'}
    disabledBgColor={'grey55'}
    {...props}
  />
);

export const UploadButtonRound = (props: BaseIconButtonProps) => {
  return (
    <IconButton
      IconComponent={UploadIcon}
      round
      size={18}
      boxSize={'sm'}
      color={'grey15'}
      bgColor={'grey95'}
      hoverFill={'grey75'}
      {...props}
    />
  );
};

// Base IconButton
export interface BaseIconButtonProps extends PropsWithChildren {
  link?: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  download?: string;
  disabled?: boolean;
  round?: boolean;
  underline?: boolean;
  displayAsLink?: boolean;
  noPointer?: boolean;
  size?: number;
  color?: ThemeColorKeyType;
  hoverColor?: ThemeColorKeyType;
  bgColor?: ThemeColorKeyType;
  borderColor?: ThemeColorKeyType;
  hoverFill?: ThemeColorKeyType;
  disabledColor?: ThemeColorKeyType;
  disabledBgColor?: ThemeColorKeyType;
  dmsExportTime?: string;
  boxSize?: BoxSizeType;
  tooltip?: string;
  dataCy?: string;
}

export interface IconSvgButtonProps extends BaseIconButtonProps {
  IconComponent: IconType;
  hoverFill?: ThemeColorKeyType;
}

export type BoxSizeType = 'xsm' | 'sm' | 'smd' | 'md' | 'lg' | 'xl';

const getBoxSize = (boxSize?: BoxSizeType) => {
  if (boxSize === 'xl') {
    return 86;
  }
  if (boxSize === 'lg') {
    return 50;
  }
  if (boxSize === 'md') {
    return 45;
  }
  if (boxSize === 'smd') {
    return 40;
  }
  if (boxSize === 'xsm') {
    return 20;
  }
  return 30;
};

const IconBox = styled.div<{ round?: boolean; boxSize?: BoxSizeType }>`
  display: flex;
  width: ${({ round, boxSize }) => (round ? getBoxSize(boxSize) : (4 / 3) * Number(getBoxSize(boxSize)))}px;
  height: ${({ boxSize }) => getBoxSize(boxSize)};
`;

const getIconColor = (color?: ThemeColorKeyType, disabled?: boolean, disabledColor?: ThemeColorKeyType) => {
  if (disabled) {
    return disabledColor ?? 'grey85';
  }
  if (color) {
    return color;
  }
  return 'brand_black';
};

const getActionBoxBgColor = (
  bgColor?: ThemeColorKeyType,
  disabled?: boolean,
  disabledBgColor?: ThemeColorKeyType,
): ThemeColorKeyType => {
  if (disabled) {
    return disabledBgColor ?? 'transparent';
  }
  if (bgColor) {
    return bgColor;
  }
  return 'transparent';
};

const ActionBoxSvg = styled.div<{
  round?: boolean;
  bgColor?: ThemeColorKeyType;
  borderColor?: ThemeColorKeyType;
  disabled?: boolean;
  noPointer?: boolean;
  disabledBgColor?: ThemeColorKeyType;
  boxSize?: BoxSizeType;
  hoverFill?: ThemeColorKeyType;
  displayAsLink?: boolean;
  iconColor?: ThemeColorKeyType;
  hoverIconColor?: ThemeColorKeyType;
}>`
  display: flex;
  height: ${({ boxSize }) => getBoxSize(boxSize)}px;
  align-items: center;
  width: ${({ boxSize, round, displayAsLink }) =>
    round && displayAsLink ? getBoxSize(boxSize) + 'px' : 'fit-content'};
  user-select: none;
  cursor: ${({ disabled, noPointer }) => (noPointer ? 'auto' : disabled ? 'not-allowed' : 'pointer')};
  border-radius: ${({ round }) => (round ? '50%' : '3px')};
  background-color: ${({ bgColor, disabled, disabledBgColor, theme }) =>
    theme.color[getActionBoxBgColor(bgColor, disabled, disabledBgColor)]};
  position: relative;
  color: ${(props) => (props.iconColor ? props.theme?.color[props.iconColor] : 'none')};
  border-color: ${(props) => (props.borderColor ? `solid 2x props.theme?.color[props.borderColor]` : 'none')};

  border: ${({ borderColor, theme }) => (borderColor ? `2px solid ${theme.color[borderColor]}` : 'none')};

  :hover {
    background-color: ${(props) => (props.hoverFill ? props.theme?.color[props.hoverFill] : 'transparent')};
    position: relative;
    filter: ${({ noPointer }) => (noPointer ? 'inherit' : 'brightness(0.9)')};
    // transform: scale(1.01); // not sure about design , some buttons resizes, some not..
    color: ${(props) => (props.hoverIconColor ? props.theme?.color[props.hoverIconColor] : 'none')};
  }

  a {
    color: inherit;
    width: inherit;
    height: inherit;
    border-radius: inherit;
  }
`;

export const IconButton = ({
  link,
  onClick,
  onMouseEnter,
  children,
  IconComponent,
  download,
  noPointer,
  size,
  color,
  hoverColor,
  bgColor,
  borderColor,
  disabledColor,
  disabled,
  disabledBgColor,
  round,
  displayAsLink,
  boxSize,
  hoverFill,
  tooltip,
  dataCy,
}: IconSvgButtonProps) => {
  const nonce = useNonce();
  const handleClick = useCallback(() => {
    if (onClick && !disabled) {
      onClick();
    }
  }, [disabled, onClick]);

  return (
    <WithTooltip title={tooltip}>
      <ActionBoxSvg
        onClick={handleClick}
        onMouseEnter={onMouseEnter}
        bgColor={bgColor}
        borderColor={borderColor}
        round={round}
        disabled={disabled}
        noPointer={noPointer}
        disabledBgColor={disabledBgColor}
        boxSize={boxSize}
        hoverFill={hoverFill}
        displayAsLink={displayAsLink}
        iconColor={getIconColor(color, disabled, disabledColor)}
        hoverIconColor={getIconColor(hoverColor, disabled, disabledColor)}
        data-cy={dataCy}
      >
        <WithLink
          to={!disabled ? link : undefined}
          external={hasData(download)}
          newTab={hasData(download)}
          download={download}
        >
          <CenterFlex style={{ borderRadius: 'inherit' }}>
            <Flex size={0} align={'center'} justify={'center'}>
              <IconBox round={round} boxSize={boxSize}>
                <Flex align={'center'} justify={'center'}>
                  <IconComponent id={nonce} width={size ?? 8} height={size ?? 8} />
                </Flex>
              </IconBox>
            </Flex>
            {children && (
              <Flex>
                <Box width={0}>
                  <MarginBox ml={5}>
                    <Box width={200}>
                      <Text
                        type={displayAsLink ? 'light_14_100' : 'light_14_black_65'}
                        displayStyle={displayAsLink && 'link'}
                        decoration={displayAsLink ? 'underline' : 'none'}
                        cursor={'pointer'}
                      >
                        {children}
                      </Text>
                    </Box>
                  </MarginBox>
                </Box>
              </Flex>
            )}
          </CenterFlex>
        </WithLink>
      </ActionBoxSvg>
    </WithTooltip>
  );
};

import { ClarionLinkResponse } from '@1po/1po-bff-fe-spec/generated/clarion/ClarionLinkResponse';
import { GET_CLARION_LINK_RESPONSE } from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

// Init state
import { createSelector } from 'reselect';
import { RootState } from 'app/AppStore';
import { getDefaultSearchData, SearchData } from 'utils';
import { CLARION_NAMESPACE, ClarionState, GET_CLARION_LINK } from './Clarion.types';

const initialState: ClarionState = {
  clarionLink: getDefaultSearchData(),
};

export const fetchClarionLinkRequest = createAction(GET_CLARION_LINK);

export const fetchClarionLinkResponse = createAction(GET_CLARION_LINK_RESPONSE);

// Slice
const slice = createSlice({
  name: CLARION_NAMESPACE,
  initialState,
  reducers: {
    setInitialState: () => initialState,
    setClarionLink: (state, { payload }: PayloadAction<SearchData<ClarionLinkResponse>>) => {
      state.clarionLink = payload;
    },
    clearClarionLinkRequest: (state) => {
      state.clarionLink = getDefaultSearchData();
    },
  },
});

// Actions
export const { setInitialState, setClarionLink, clearClarionLinkRequest } = slice.actions;

// Getters/Selectors
export const getClarionLink = createSelector(
  (state: RootState) => state.clarion.clarionLink,
  (clarionLink) => clarionLink,
);

// Export reducer
export default slice.reducer;

const PREFIX = '1PO';
const APP_CONTEXT = `${PREFIX}/APP_CONTEXT`;
const BASKET = `${PREFIX}/BASKET`;
const EXTERNAL_BASKET = `${PREFIX}/EXTERNAL_BASKET`;
const BACKOFFICE = `${PREFIX}/BACKOFFICE`;
const CATALOG = `${PREFIX}/CATALOG`;
const COMMON = `${PREFIX}/COMMON`;
const ESTIMATE = `${PREFIX}/ESTIMATE`;
const EXTERNAL_ORDER = `${PREFIX}/EXTERNAL_ORDER`;
const GARAGE = `${PREFIX}/GARAGE`;
const IMAGE = `${PREFIX}/IMAGE`;
const ORDER = `${PREFIX}/ORDER`;
const USER = `${PREFIX}/USER`;
const DELEGATION = `${PREFIX}/DELEGATION`;
const EMAIL = `${PREFIX}/EMAIL`;
const TIRES = `${PREFIX}/TIRES`;
const DMS = `${PREFIX}/DMS`;
const ORDER_LIST = `${PREFIX}/ORDER_LIST`;
const MY_STORE = `${PREFIX}/MY_STORE`;
const CLARION = `${PREFIX}/CLARION`;
// Responses
export const COMMAND_PROCESSING_RESPONSE = `${COMMON}/COMMAND_PROCESSING_RESPONSE`;
export const COMMAND_PROCESSING_RESPONSE_WITH_DATA = `${COMMON}/COMMAND_PROCESSING_RESPONSE_WITH_DATA`;
export const QUERY_SUCCESS_RESPONSE = `${COMMON}/QUERY_SUCCESS_RESPONSE`;
export const QUERY_NO_DATA_RESPONSE = `${COMMON}/QUERY_NO_DATA_RESPONSE`;
export const QUERY_INVALID_RESPONSE = `${COMMON}/QUERY_INVALID_RESPONSE`;
export const QUERY_ERROR_RESPONSE = `${COMMON}/QUERY_ERROR_RESPONSE`;
// Clarion
export const GET_CLARION_LINK_RESPONSE = `${CLARION}/GET_CLARION_LINK_RESPONSE`;
// DataHub
export const SEARCH_VEHICLE_RESPONSE = `${CATALOG}/SEARCH_VEHICLE_RESPONSE`;
export const GET_VEHICLE_BRANDS_RESPONSE = `${CATALOG}/GET_VEHICLE_BRANDS_RESPONSE`;
export const GET_VEHICLE_ENGINES_RESPONSE = `${CATALOG}/GET_VEHICLE_ENGINES_RESPONSE`;
export const GET_VEHICLE_SEARCH_HISTORY_RESPONSE = `${CATALOG}/GET_VEHICLE_SEARCH_HISTORY_RESPONSE`;
export const SEARCH_VEHICLE_EXPLODED_VIEW_COUNT_RESPONSE = `${CATALOG}/SEARCH_VEHICLE_EXPLODED_COUNT_RESPONSE`;
export const SEARCH_VEHICLE_EXPLODED_VIEW_RESPONSE = `${CATALOG}/SEARCH_VEHICLE_EXPLODED_VIEW_RESPONSE`;
export const GET_VEHICLE_CATEGORY_IMAGES_RESPONSE = `${CATALOG}/GET_VEHICLE_CATEGORY_IMAGES_RESPONSE`;
export const EXPLODED_TREE_RESPONSE = `${CATALOG}/EXPLODED_TREE_RESPONSE`;
export const GET_PLATE_DETAIL_RESPONSE = `${CATALOG}/GET_PLATE_DETAIL_RESPONSE`;
export const GET_LEVEL3_DETAILS_RESPONSE = `${CATALOG}/GET_LEVEL3_DETAILS_RESPONSE`;
export const GET_REFERENCE_DETAILS_RESPONSE = `${CATALOG}/GET_REFERENCE_DETAILS_RESPONSE`;
export const GET_REUSE_STOCK_RESPONSE = `${CATALOG}/GET_REUSE_STOCK_RESPONSE`;
export const GET_PLATE_REFERENCES_RESPONSE = `${CATALOG}/GET_PLATE_REFERENCES_RESPONSE`;
export const GET_REFERENCES_STOCKS_RESPONSE = `${CATALOG}/GET_REFERENCES_STOCKS_RESPONSE`;
export const CHECK_IF_STOCKS_ARE_UP_TO_DATE_RESPONSE = `${CATALOG}/CHECK_IF_STOCKS_ARE_UP_TO_DATE_RESPONSE`;
export const GET_REFERENCES_PRICE_RESPONSE = `${CATALOG}/GET_REFERENCES_PRICE_RESPONSE`;
export const GET_REFERENCES_REPAIR_PRICE_RESPONSE = `${CATALOG}/GET_REFERENCES_REPAIR_PRICE_RESPONSE`;
export const GET_REFERENCES_DISCOUNTS_RESPONSE = `${CATALOG}/GET_REFERENCES_DISCOUNTS_RESPONSE`;
export const REMOVE_REFERENCES_DISCOUNTS_RESPONSE = `${CATALOG}/REMOVE_REFERENCES_DISCOUNTS_RESPONSE`;
export const GET_MARKETING_REFERENCES_RESPONSE = `${CATALOG}/GET_MARKETING_REFERENCES_RESPONSE`;
export const GET_PLATES_FOR_REFERENCE_RESPONSE = `${CATALOG}/GET_PLATES_FOR_REFERENCE_RESPONSE`;
export const GET_LABOR_TIMES_RESPONSE = `${CATALOG}/LABOR_TIME/GET_LABOR_TIMES_RESPONSE`;
export const GET_IAM_LABOR_TIMES_RESPONSE = `${CATALOG}/LABOR_TIME/GET_IAM_LABOR_TIMES_RESPONSE`;
export const GET_LABOR_TIME_IDS_BY_GENERIC_PARTS_RESPONSE = `${CATALOG}/LABOR_TIME/GET_LABOR_TIME_IDS_BY_GENERIC_PARTS_RESPONSE`;
export const GET_USER_PROFILE_RESPONSE = `${USER}/GET_USER_PROFILE_RESPONSE`;
export const GET_USER_SELLERS_RESPONSE = `${USER}/GET_USER_SELLERS_RESPONSE`;
export const GET_USER_SELLERS_PENDING_WARNING = `${USER}/GET_USER_SELLERS_PENDING_WARNING`;
export const GET_USER_BASKET_RESPONSE = `${BASKET}/GET_USER_BASKET_RESPONSE`;
export const GET_DELEGATION_CONNECT_AS_RESPONSE = `${DELEGATION}/GET_DELEGATION_CONNECT_AS_RESPONSE`;
export const NOTIFY_ABOUT_DELEGATION_DISCONNECT = `${DELEGATION}/NOTIFY_ABOUT_DELEGATION_DISCONNECT`;
export const GET_DELEGATION_DISCONNECT_FROM_RESPONSE = `${DELEGATION}/GET_DELEGATION_DISCONNECT_FROM_RESPONSE`;
export const GET_POSSIBLE_SELLERS_TO_DELEGATE_RESPONSE = `${DELEGATION}/GET_POSSIBLE_SELLERS_TO_DELEGATE_RESPONSE`;
export const GET_POSSIBLE_BUYERS_TO_DELEGATE_RESPONSE = `${DELEGATION}/GET_POSSIBLE_BUYERS_TO_DELEGATE_RESPONSE`;
export const GET_BUILD_VERSIONS_RESPONSE = `${APP_CONTEXT}/GET_BUILD_VERSIONS_RESPONSE`;
export const GET_APPLICATION_CONFIG_RESPONSE = `${APP_CONTEXT}/GET_APPLICATION_CONFIG_RESPONSE`;
export const CHECKOUT_ORDER_RESPONSE = `${ORDER}/CHECKOUT_ORDER_RESPONSE`;
export const GET_ORDER_LIST_RESPONSE = `${ORDER}/GET_ORDER_LIST_RESPONSE`;
export const GET_ORDER_BY_ID_RESPONSE = `${ORDER}/GET_ORDER_BY_ID_RESPONSE`;
export const RE_ADD_ORDERED_REFERENCES_TO_BASKET_RESPONSE = `${ORDER}/RE_ADD_ORDERED_REFERENCES_TO_BASKET_RESPONSE`;
export const GET_EXTERNAL_ORDER_LIST_RESPONSE = `${EXTERNAL_ORDER}/GET_ORDER_LIST_RESPONSE`;
export const ADD_BASKET_OTHER_REFERENCE_RESPONSE = `${BASKET}/ADD_BASKET_OTHER_REFERENCE_RESPONSE`;
export const UPLOAD_BASKET_REFERENCES_FILE_RESPONSE = `${BASKET}/UPLOAD_REFERENCES_FILE_RESPONSE`;
export const BASKET_ATTACH_OTHER_REFERENCE_CAR_RESPONSE = `${BASKET}/BASKET_ATTACH_OTHER_REFERENCE_CAR_RESPONSE`;
export const BASKET_INVALID_REFERENCE_QUANTITY_UPDATE_RESPONSE = `${BASKET}/BASKET_INVALID_REFERENCE_QUANTITY_UPDATE_RESPONSE`;
export const BASKET_ATTACH_OTHER_REFERENCE_CAR_NONCOMPATIBLE_RESPONSE = `${BASKET}/BASKET_ATTACH_OTHER_REFERENCE_CAR_NONCOMPATIBLE_RESPONSE`;
export const GET_EXTERNAL_BASKET_RESPONSE = `${EXTERNAL_BASKET}/GET_EXTERNAL_BASKET_RESPONSE`;
export const ADD_EXTERNAL_BASKET_TO_USER_BASKET_RESPONSE = `${EXTERNAL_BASKET}/ADD_EXTERNAL_BASKET_TO_USER_BASKET_RESPONSE`;
export const GET_LATEST_ESTIMATE_RESPONSE = `${ESTIMATE}/GET_LATEST_ESTIMATE_RESPONSE`;
export const ADD_ESTIMATE_KNOWN_REFERENCE_RESPONSE = `${ESTIMATE}/ADD_ESTIMATE_KNOWN_REFERENCE_RESPONSE`;
export const ADD_ESTIMATE_TIRE_RESPONSE = `${ESTIMATE}/ADD_ESTIMATE_TIRE_RESPONSE`;
export const GET_ESTIMATE_BY_ID_RESPONSE = `${ESTIMATE}/GET_ESTIMATE_BY_ID_RESPONSE`;
export const GET_ESTIMATE_ID_TO_DELETE_RESPONSE = `${ESTIMATE}/GET_ESTIMATE_ID_TO_DELETE_RESPONSE`;
export const GET_ESTIMATE_ID_TO_HIDE_RESPONSE = `${ESTIMATE}/GET_ESTIMATE_ID_TO_HIDE_RESPONSE`;
export const GET_ESTIMATE_ID_TO_SHOW_RESPONSE = `${ESTIMATE}/GET_ESTIMATE_ID_TO_SHOW_RESPONSE`;
export const GET_ESTIMATE_HISTORY_RESPONSE = `${ESTIMATE}/GET_ESTIMATE_HISTORY_RESPONSE`;
export const GET_ESTIMATE_SETTINGS_RESPONSE = `${ESTIMATE}/GET_ESTIMATE_SETTINGS_RESPONSE`;
export const GET_GARAGE_INFO_RESPONSE = `${GARAGE}/GET_GARAGE_INFO_RESPONSE`;
export const GARAGE_EMAIL_UPDATE = `${GARAGE}/GARAGE_EMAIL_UPDATE`;
export const GET_FULL_TEXT_AUTOCOMPLETE_RESPONSE = `${CATALOG}/GET_FULL_TEXT_AUTOCOMPLETE_RESPONSE`;
export const GET_TEXT_SEARCH_RESPONSE = `${CATALOG}/GET_TEXT_SEARCH_RESPONSE`;
export const GET_OTS_INFORMATION_RESPONSE = `${CATALOG}/GET_OTS_INFORMATION_RESPONSE`;
export const GET_REFERENCE_TEXT_SEARCH_RESPONSE = `${CATALOG}/GET_REFERENCE_TEXT_SEARCH_RESPONSE`;
export const GET_PICTURE_RESPONSE = `${IMAGE}/GET_PICTURE_RESPONSE`;
export const SEND_EMAIL_FROM_GARAGE_RESPONSE = `${EMAIL}/SEND_EMAIL_FROM_GARAGE_RESPONSE`;
export const SEND_RETURN_REQUEST_EMAIL_RESPONSE = `${EMAIL}/SEND_RETURN_REQUEST_EMAIL_RESPONSE`;
export const SEND_EMAIL_TO_ASSISTANCE_RESPONSE = `${EMAIL}/SEND_EMAIL_TO_ASSISTANCE_RESPONSE`;
export const SEND_EMAIL_DOCUMENTATION_ALERT_MOTRIO_RESPONSE = `${EMAIL}/SEND_EMAIL_DOCUMENTATION_ALERT_MOTRIO_RESPONSE`;
export const GET_UNIVERSAL_PRODUCTS_TREE_RESPONSE = `${CATALOG}/GET_UNIVERSAL_PRODUCTS_TREE_RESPONSE`;
export const GET_UNIVERSAL_PRODUCTS_RESPONSE = `${CATALOG}/GET_UNIVERSAL_PRODUCTS_RESPONSE`;
export const GET_VEHICLE_TECHNICAL_CRITERIA_RESPONSE = `${CATALOG}/GET_VEHICLE_TECHNICAL_CRITERIA_RESPONSE`;
export const GET_IAM_VEHICLE_TECHNICAL_CRITERIA_RESPONSE = `${CATALOG}/GET_IAM_VEHICLE_TECHNICAL_CRITERIA_RESPONSE`;
export const BACKOFFICE_ELECTRONIC_PARTS_REPAIR_UPLOAD_REFERENCES_FILE_RESPONSE = `${BACKOFFICE}/ELECTRONIC_PARTS_REPAIR/UPLOAD_REFERENCES_FILE_RESPONSE`;
export const BACKOFFICE_ELECTRONIC_PARTS_REPAIR_REPLACE_REFERENCES_FILE_RESPONSE = `${BACKOFFICE}/ELECTRONIC_PARTS_REPAIR/REPLACE_REFERENCES_FILE_RESPONSE`;
export const BACKOFFICE_ELECTRONIC_PARTS_REPAIR_GET_FILE_RESPONSE = `${BACKOFFICE}/ELECTRONIC_PARTS_REPAIR/GET_FILE_RESPONSE`;
export const BACKOFFICE_DELIVERY_LEAD_TIME_RESPONSE = `${BACKOFFICE}/DELIVERY_LEAD_TIME_RESPONSE`;
export const BACKOFFICE_DELIVERY_LEAD_TIME_UPDATE_RESPONSE = `${BACKOFFICE}/DELIVERY_LEAD_TIME_UPDATE_RESPONSE`;
export const BACKOFFICE_PROMOTION_RESPONSE = `${BACKOFFICE}/PROMOTION_RESPONSE`;
export const BACKOFFICE_FULL_PROMOTION_RESPONSE = `${BACKOFFICE}/FULL_PROMOTION_RESPONSE`;
export const BACKOFFICE_DASHBOARD_PROMOTIONS_RESPONSE = `${BACKOFFICE}/DASHBOARD_PROMOTIONS_RESPONSE`;
export const BACKOFFICE_SAVE_PROMOTION_RESPONSE = `${BACKOFFICE}/SAVE_PROMOTION_RESPONSE`;
export const BACKOFFICE_ADJUST_PROMOTION_BY_R1_RESPONSE = `${BACKOFFICE}/ADJUST_PROMOTION_BY_R1_RESPONSE`;
export const BACKOFFICE_PUBLISH_PROMOTION_RESPONSE = `${BACKOFFICE}/PUBLISH_PROMOTION_RESPONSE`;
export const BACKOFFICE_ARCHIVE_PROMOTION_RESPONSE = `${BACKOFFICE}/ARCHIVE_PROMOTION_RESPONSE`;
export const BACKOFFICE_DELETE_PROMOTION_RESPONSE = `${BACKOFFICE}/DELETE_PROMOTION_RESPONSE`;
export const BACKOFFICE_DELETE_PROMOTION_SUBSCRIPTION_RESPONSE = `${BACKOFFICE}/DELETE_PROMOTION_SUBSCRIPTION_RESPONSE`;
export const BACKOFFICE_PROMOTION_BANNERS_RESPONSE = `${BACKOFFICE}/PROMOTION_BANNERS_RESPONSE`;
export const BACKOFFICE_PROMOTION_BANNERS_UPDATE_RESPONSE = `${BACKOFFICE}/BACKOFFICE_PROMOTION_BANNERS_UPDATE_RESPONSE`;
export const BACKOFFICE_REFERENCES_DISCOUNTS_RESPONSE = `${BACKOFFICE}/PROMOTION_REFERENCES_DISCOUNTS_RESPONSE`;
export const BACKOFFICE_PROMOTION_UPLOAD_REFERENCES_FILE_RESPONSE = `${BACKOFFICE}/PROMOTION/UPLOAD_REFERENCES_FILE_RESPONSE`;
export const BACKOFFICE_PRODUCTS_VALIDATION_RESPONSE = `${BACKOFFICE}/PROMOTION/PRODUCTS_VALIDATION_RESPONSE`;
export const BACKOFFICE_NUMBER_OF_NOT_YET_ADJUSTED_PROMOTIONS_RESPONSE = `${BACKOFFICE}/NUMBER_OF_NOT_YET_ADJUSTED_PROMOTIONS`;
export const BACKOFFICE_PROMOTION_UPLOAD_TARGETED_AUDIENCE_FILE_RESPONSE = `${BACKOFFICE}/PROMOTION/UPLOAD_TARGETED_AUDIENCE_FILE_RESPONSE`;
// BACKOFFICE/R1_NETWORK
export const BACKOFFICE_GET_RETURNS_FEATURE_SETTINGS_RESPONSE = `${BACKOFFICE}/GET_RETURNS_FEATURE_SETTINGS_RESPONSE`;
export const BACKOFFICE_SAVE_RETURNS_FEATURE_SETTINGS_RESPONSE = `${BACKOFFICE}/SAVE_RETURNS_FEATURE_SETTINGS_RESPONSE`;
export const BACKOFFICE_INFORMATION_RESPONSE = `${BACKOFFICE}/INFORMATION_RESPONSE`;
export const BACKOFFICE_INFORMATIONS_RESPONSE = `${BACKOFFICE}/INFORMATIONS_RESPONSE`;
export const BACKOFFICE_SAVE_INFORMATION_RESPONSE = `${BACKOFFICE}/SAVE_INFORMATION_RESPONSE`;
export const BACKOFFICE_PUBLISH_INFORMATION_RESPONSE = `${BACKOFFICE}/PUBLISH_INFORMATION_RESPONSE`;
export const BACKOFFICE_ARCHIVE_INFORMATION_RESPONSE = `${BACKOFFICE}/ARCHIVE_INFORMATION_RESPONSE`;
export const BACKOFFICE_INFORMATION_BANNERS_RESPONSE = `${BACKOFFICE}/INFORMATION_BANNERS_RESPONSE`;
export const BACKOFFICE_INFORMATION_BANNER_UPDATE = `${BACKOFFICE}/INFORMATION_BANNER_UPDATE`;
export const BACKOFFICE_SAVE_INFORMATION_TEMPLATE_RESPONSE = `${BACKOFFICE}/SAVE_INFORMATION_TEMPLATE_RESPONSE`;
export const BACKOFFICE_CROSS_SELLING_GET_FILE_RESPONSE = `${BACKOFFICE}/CROSS_SELLING_GET_FILE_RESPONSE`;
export const BACKOFFICE_CROSS_SELLING_GET_FILE_PROGRESS_RESPONSE = `${BACKOFFICE}/CROSS_SELLING_GET_FILE_PROGRESS_RESPONSE`;
export const BACKOFFICE_CROSS_SELLING_GET_REFERENCES_RESPONSE = `${BACKOFFICE}/CROSS_SELLING_GET_REFERENCES_RESPONSE`;
export const BACKOFFICE_CROSS_SELLING_GET_REFERENCES_FILE_RESPONSE = `${BACKOFFICE}/CROSS_SELLING_GET_REFERENCES_FILE_RESPONSE`;
export const BACKOFFICE_CROSS_SELLING_UPLOAD_FILE_RESPONSE = `${BACKOFFICE}/CROSS_SELLING_UPLOAD_FILE_RESPONSE`;
export const BACKOFFICE_CROSS_SELLING_DELETE_FILE_RESPONSE = `${BACKOFFICE}/CROSS_SELLING_DELETE_FILE_RESPONSE`;
export const BACKOFFICE_UPDATE_CURRENT_PROMOTIONS_RESPONSE = `${BACKOFFICE}/UPDATE_CURRENT_PROMOTIONS_RESPONSE`;
export const BACKOFFICE_CURRENT_PROMOTIONS_RESPONSE = `${BACKOFFICE}/CURRENT_PROMOTIONS_RESPONSE`;
export const GET_MAINTENANCE_PLAN_RESPONSE = `${CATALOG}/GET_MAINTENANCE_PLAN_RESPONSE`;
export const GET_MAINTENANCE_VALUES_RESPONSE = `${CATALOG}/GET_MAINTENANCE_VALUES_RESPONSE`;
// IAM
export const GET_IAM_VEHICLE_BRANDS_RESPONSE = `${CATALOG}/GET_IAM_VEHICLE_BRANDS_RESPONSE`;
export const GET_IAM_VEHICLE_MODELS_RESPONSE = `${CATALOG}/GET_IAM_VEHICLE_MODELS_RESPONSE`;
export const GET_IAM_VEHICLE_VERSIONS_RESPONSE = `${CATALOG}/GET_IAM_VEHICLE_VERSIONS_RESPONSE`;
export const GET_EXPLODED_TREE_IAM_RESPONSE = `${CATALOG}/GET_EXPLODED_TREE_IAM_RESPONSE`;
export const GET_IAM_REFERENCES_RESPONSE = `${CATALOG}/IAM/GET_REFERENCES_RESPONSE`;
export const GET_IAM_TECHNICAL_DATA_TREE_RESPONSE = `${CATALOG}/TECHNICAL_DATA/GET_IAM_TECHNICAL_DATA_TREE_RESPONSE`;
export const GET_IAM_TECHNICAL_DATA_DETAILS_RESPONSE = `${CATALOG}/TECHNICAL_DATA/GET_IAM_TECHNICAL_DATA_DETAILS_RESPONSE`;
export const GET_IAM_TEXT_SEARCH_RESPONSE = `${CATALOG}/GET_IAM_TEXT_SEARCH_RESPONSE`;
export const GET_IAM_EQUIVALENT_REFERENCES_RESPONSE = `${CATALOG}/GET_IAM_EQUIVALENT_REFERENCES_RESPONSE`;
export const GET_IAM_OPERATIONS_TREE_RESPONSE = `${CATALOG}/SERVICE_OPERATIONS/GET_IAM_OPERATIONS_TREE_RESPONSE`;
export const GET_IAM_OPERATIONS_FAMILIES_RESPONSE = `${CATALOG}/SERVICE_OPERATIONS/GET_IAM_OPERATIONS_FAMILIES_RESPONSE`;
export const GET_IAM_AVAILABLE_MODULES_RESPONSE = `${CATALOG}/IAM/GET_IAM_AVAILABLE_MODULES_RESPONSE`;
export const GET_IAM_SERVICE_PROPOSAL_RESPONSE = `${CATALOG}/SERVICE_OPERATIONS/GET_IAM_SERVICE_PROPOSAL_RESPONSE`;
export const GET_IAM_SERVICE_PROPOSAL_OPERATIONS_RESPONSE = `${CATALOG}/SERVICE_OPERATIONS/GET_IAM_SERVICE_PROPOSAL_OPERATIONS_RESPONSE`;
export const GET_IAM_SERVICE_CHECKLIST_RESPONSE = `${CATALOG}/SERVICE_OPERATIONS/GET_IAM_SERVICE_CHECKLIST_RESPONSE`;
export const GET_IAM_REPAIR_METHODS_RESPONSE = `${CATALOG}/REPAIR_METHODS/GET_IAM_LIST_REPAIR_METHODS_RESPONSE`;
export const GET_IAM_REPAIR_METHODS_TREE_RESPONSE = `${CATALOG}/REPAIR_METHODS/GET_IAM_REPAIR_METHODS_TREE_RESPONSE`;
export const GET_IAM_REPAIR_METHOD_DETAIL_RESPONSE = `${CATALOG}/REPAIR_METHODS/GET_IAM_REPAIR_METHOD_DETAIL_RESPONSE`;
//TIRES
export const VEHICLE_TIRES_RESPONSE = `${TIRES}/VEHICLE_TIRES_RESPONSE`;
export const TIRES_FULLTEXT_SEARCH_RESPONSE = `${TIRES}/TIRES_FULLTEXT_SEARCH_RESPONSE`;
export const TIRES_SEARCH_DIMENSIONS_RESPONSE = `${TIRES}/TIRES_SEARCH_DIMENSIONS_RESPONSE`;
export const TIRES_SEARCH_UNIQUE_DIMENSIONS_RESPONSE = `${TIRES}/TIRES_SEARCH_UNIQUE_DIMENSIONS_RESPONSE`;
export const TIRES_SEARCH_PRODUCTS_RESPONSE = `${TIRES}/TIRES_SEARCH_PRODUCTS_RESPONSE`;
export const GET_IAM_TIRES_RESPONSE = `${TIRES}/IAM/GET_IAM_TIRES_RESPONSE`;
export const GET_TIRE_BRAND_CATEGORIES_RESPONSE = `${TIRES}/GET_TIRE_BRAND_CATEGORIES_RESPONSE`;
// DMS
export const GET_AVAILABLE_DMS_SERVICES_RESPONSE = `${DMS}/GET_AVAILABLE_DMS_SERVICES_RESPONSE`;
export const SEND_ESTIMATE_TO_DMS_RESPONSE = `${DMS}/SEND_ESTIMATE_TO_DMS_RESPONSE`;
// ORDER_LIST
export const UPLOAD_ORDER_LIST_REFERENCES_RESPONSE = `${ORDER_LIST}/UPLOAD_ORDER_LIST_REFERENCES_RESPONSE`;
export const UPLOAD_ORDER_LIST_REFERENCES_FILE_RESPONSE = `${ORDER_LIST}/UPLOAD_ORDER_LIST_REFERENCES_FILE_RESPONSE`;
// MY_STORE
// BUNDLES
export const GET_MY_STORE_BUNDLES_RESPONSE = `${MY_STORE}/GET_MY_STORE_BUNDLES_RESPONSE`;
// TIRES
export const GET_MY_STORE_TIRES_RESPONSE = `${MY_STORE}/GET_MY_STORE_TIRES_RESPONSE`;
// Errors
// DataHub
export const SEARCH_VEHICLE_ERROR = `${CATALOG}/SEARCH_VEHICLE_ERROR`;
export const GET_REFERENCE_DETAILS_ERROR = `${CATALOG}/GET_REFERENCE_DETAILS_ERROR`;
export const GET_REUSE_STOCK_ERROR = `${CATALOG}/GET_REUSE_STOCK_ERROR`;
export const GET_VEHICLE_BRANDS_ERROR = `${CATALOG}/GET_VEHICLE_BRANDS_ERROR`;
export const GET_VEHICLE_ENGINES_ERROR = `${CATALOG}/GET_VEHICLE_ENGINES_ERROR`;
export const GET_VEHICLE_SEARCH_HISTORY_ERROR = `${CATALOG}/GET_VEHICLE_SEARCH_HISTORY_ERROR`;
export const EXPLODED_TREE_ERROR = `${CATALOG}/EXPLODED_TREE_ERROR`;
export const GET_LEVEL3_ERROR = `${CATALOG}/GET_LEVEL3_ERROR`;
export const GET_PLATE_REFERENCES_ERROR = `${CATALOG}/GET_PLATE_REFERENCES_ERROR`;
export const GET_REFERENCES_STOCKS_ERROR = `${CATALOG}/GET_REFERENCES_STOCKS_ERROR`;
export const GET_REFERENCES_PRICE_ERROR = `${CATALOG}/GET_REFERENCES_PRICE_ERROR`;
export const GET_REFERENCES_REPAIR_PRICE_ERROR = `${CATALOG}/GET_REFERENCES_REPAIR_PRICE_ERROR`;
export const GET_MARKETING_REFERENCES_ERROR = `${CATALOG}/GET_MARKETING_REFERENCES_ERROR`;
export const GET_PLATES_FOR_REFERENCE_ERROR = `${CATALOG}/GET_PLATES_FOR_REFERENCE_ERROR`;
export const GET_LABOR_TIMES_ERROR = `${CATALOG}/LABOR_TIME/GET_LABOR_TIMES_ERROR`;
export const GET_LABOR_TIME_IDS_BY_GENERIC_PARTS_ERROR = `${CATALOG}/LABOR_TIME/GET_LABOR_TIME_IDS_BY_GENERIC_PARTS_ERROR`;
export const GET_IAM_LABOR_TIMES_ERROR = `${CATALOG}/LABOR_TIME/GET_IAM_LABOR_TIMES_ERROR`;
export const GET_IAM_TECHNICAL_DATA_TREE_ERROR = `${CATALOG}/TECHNICAL_DATA/GET_IAM_TECHNICAL_DATA_TREE_ERROR`;
export const GET_IAM_TECHNICAL_DATA_DETAILS_ERROR = `${CATALOG}/TECHNICAL_DATA/GET_IAM_TECHNICAL_DATA_DETAILS_ERROR`;
export const GET_USER_BASKET_ERROR = `${BASKET}/GET_USER_BASKET_ERROR`;
export const GET_USER_SELLERS_ERROR = `${USER}/GET_USER_SELLERS_ERROR`;
export const GET_DELEGATION_CONNECT_AS_RESPONSE_ERROR = `${DELEGATION}/GET_DELEGATION_CONNECT_AS_RESPONSE_ERROR`;
export const GET_POSSIBLE_SELLERS_TO_DELEGATE_RESPONSE_ERROR = `${DELEGATION}/GET_POSSIBLE_SELLERS_TO_DELEGATE_RESPONSE_ERROR`;
export const GET_POSSIBLE_BUYERS_TO_DELEGATE_RESPONSE_ERROR = `${DELEGATION}/GET_POSSIBLE_BUYERS_TO_DELEGATE_RESPONSE_ERROR`;
export const GET_BASKET_REFERENCES_STOCKS_ERROR = `${BASKET}/GET_BASKET_REFERENCES_STOCKS_ERROR`;
export const ADD_ESTIMATE_KNOWN_LABOR_TIME_ERROR = `${ESTIMATE}/ADD_ESTIMATE_KNOWN_LABOR_TIME_ERROR`;
export const GET_ORDER_LIST_ERROR = `${BASKET}/GET_ORDER_LIST_ERROR`;
export const GET_ORDER_BY_ID_ERROR = `${ORDER}/GET_ORDER_BY_ID_ERROR`;
export const GET_EXTERNAL_ORDER_LIST_ERROR = `${EXTERNAL_ORDER}/GET_ORDER_LIST_ERROR`;
export const UPLOAD_BASKET_REFERENCES_FILE_ERROR_99_REFERENCES = `${BASKET}/UPLOAD_BASKET_REFERENCES_FILE_ERROR_99_REFERENCES`;
export const UPLOAD_BASKET_REFERENCES_FILE_ERROR_TECHNICAL = `${BASKET}/UPLOAD_BASKET_REFERENCES_FILE_ERROR_TECHNICAL`;
export const UPLOAD_BASKET_REFERENCES_FILE_ERROR_API_CALL = `${BASKET}/UPLOAD_BASKET_REFERENCES_FILE_ERROR_API_CALL`;
export const GET_LATEST_ESTIMATE_ERROR = `${ESTIMATE}/GET_LATEST_ESTIMATE_ERROR`;
export const GET_ESTIMATE_BY_ID_ERROR = `${ESTIMATE}/GET_ESTIMATE_BY_ID_ERROR`;
export const GET_ESTIMATE_HISTORY_ERROR = `${ESTIMATE}/GET_ESTIMATE_HISTORY_ERROR`;
export const GET_ESTIMATE_SETTINGS_ERROR = `${ESTIMATE}/GET_ESTIMATE_SETTINGS_ERROR`;
export const ESTIMATE_CREATED_RESPONSE = `${ESTIMATE}/ESTIMATE_CREATED_RESPONSE`;
export const ESTIMATE_DELETED_RESPONSE = `${ESTIMATE}/ESTIMATE_DELETED_RESPONSE`;
export const AUTOCOMPLETE_BUNDLES_SEARCH_RESPONSE = `${ESTIMATE}/AUTOCOMPLETE_BUNDLES_SEARCH_RESPONSE`;
export const ESTIMATE_DELETION_CANCELED_RESPONSE = `${ESTIMATE}/ESTIMATE_DELETION_CANCELED_RESPONSE`;
export const AUTOCOMPLETE_BUNDLES_SEARCH_ERROR = `${ESTIMATE}/AUTOCOMPLETE_BUNDLES_SEARCH_ERROR`;
export const GET_GARAGE_INFO_ERROR = `${GARAGE}/GET_GARAGE_INFO_ERROR`;
export const GET_FULL_TEXT_AUTOCOMPLETE_ERROR = `${CATALOG}/GET_FULL_TEXT_AUTOCOMPLETE_ERROR`;
export const GET_TEXT_SEARCH_ERROR = `${CATALOG}/GET_TEXT_SEARCH_ERROR`;
export const GET_OTS_INFORMATION_ERROR = `${CATALOG}/GET_OTS_INFORMATION_ERROR`;
export const GET_REFERENCE_TEXT_SEARCH_ERROR = `${CATALOG}/GET_REFERENCE_TEXT_SEARCH_ERROR`;
export const GET_PICTURE_ERROR = `${IMAGE}/GET_PICTURE_ERROR`;
export const SEND_EMAIL_FROM_GARAGE_ERROR = `${EMAIL}/SEND_EMAIL_FROM_GARAGE_ERROR`;
export const SEND_EMAIL_TO_ASSISTANCE_ERROR = `${EMAIL}/SEND_EMAIL_TO_ASSISTANCE_ERROR`;
export const SEND_RETURN_REQUEST_EMAIL_ERROR = `${EMAIL}/SEND_RETURN_REQUEST_EMAIL_ERROR`;
export const IS_RETURNS_FEATURE_ENABLED_RESPONSE = `${ORDER}/IS_RETURNS_FEATURE_ENABLED_RESPONSE`;
export const IS_RETURNS_FEATURE_ENABLED_ERROR = `${ORDER}/IS_RETURNS_FEATURE_ENABLED_ERROR`;
export const SEND_EMAIL_DOCUMENTATION_ALERT_MOTRIO_ERROR = `${EMAIL}/SEND_EMAIL_DOCUMENTATION_ALERT_MOTRIO_ERROR`;
export const GET_UNIVERSAL_PRODUCTS_TREE_ERROR = `${CATALOG}/GET_UNIVERSAL_PRODUCTS_TREE_ERROR`;
export const GET_UNIVERSAL_PRODUCTS_ERROR = `${CATALOG}/GET_UNIVERSAL_PRODUCTS_ERROR`;
export const GET_VEHICLE_TECHNICAL_CRITERIA_ERROR = `${CATALOG}/GET_VEHICLE_TECHNICAL_CRITERIA_ERROR`;
export const BACKOFFICE_ELECTRONIC_PARTS_REPAIR_UPLOAD_REFERENCES_FILE_ERROR = `${BACKOFFICE}/ELECTRONIC_PARTS_REPAIR/UPLOAD_REFERENCES_FILE_ERROR`;
export const BACKOFFICE_ELECTRONIC_PARTS_REPAIR_GET_FILE_ERROR = `${BACKOFFICE}/ELECTRONIC_PARTS_REPAIR/GET_FILE_ERROR`;
export const BACKOFFICE_ELECTRONIC_PARTS_REPAIR_REPLACE_REFERENCES_FILE_ERROR = `${BACKOFFICE}/ELECTRONIC_PARTS_REPAIR/REPLACE_REFERENCES_FILE_ERROR`;
export const BASKET_ATTACH_OTHER_REFERENCE_CHECK_ERROR = `${BASKET}/BASKET_ATTACH_OTHER_REFERENCE_CHECK_ERROR`;
export const BACKOFFICE_DELIVERY_LEAD_TIME_ERROR = `${BACKOFFICE}/DELIVERY_LEAD_TIME_ERROR`;
export const BACKOFFICE_PROMOTION_ERROR = `${BACKOFFICE}/PROMOTION_ERROR`;
export const BACKOFFICE_FULL_PROMOTION_ERROR = `${BACKOFFICE}/FULL_PROMOTION_ERROR`;
export const BACKOFFICE_PROMOTION_BANNERS_ERROR = `${BACKOFFICE}/PROMOTION_BANNERS_ERROR`;
export const BACKOFFICE_REFERENCES_DISCOUNTS_ERROR = `${BACKOFFICE}/PROMOTION_REFERENCES_DISCOUNTS_ERROR`;
export const BACKOFFICE_PROMOTION_UPLOAD_REFERENCES_FILE_ERROR = `${BACKOFFICE}/PROMOTION/UPLOAD_REFERENCES_FILE_ERROR`;
export const BACKOFFICE_PROMOTION_UPLOAD_TARGETED_AUDIENCE_FILE_ERROR = `${BACKOFFICE}/PROMOTION/UPLOAD_TARGETED_AUDIENCE_FILE_ERROR`;
export const BACKOFFICE_INFORMATION_ERROR = `${BACKOFFICE}/INFORMATION_ERROR`;
export const BACKOFFICE_INFORMATIONS_ERROR = `${BACKOFFICE}/INFORMATIONS_ERROR`;
export const BACKOFFICE_SAVE_INFORMATION_ERROR = `${BACKOFFICE}/SAVE_INFORMATION_ERROR`;
export const BACKOFFICE_PUBLISH_INFORMATION_ERROR = `${BACKOFFICE}/PUBLISH_INFORMATION_ERROR`;
export const BACKOFFICE_INFORMATION_BANNERS_ERROR = `${BACKOFFICE}/INFORMATION_BANNERS_ERROR`;
export const BACKOFFICE_SAVE_INFORMATION_TEMPLATE_ERROR = `${BACKOFFICE}/BACKOFFICE_SAVE_INFORMATION_TEMPLATE_ERROR`;
export const BACKOFFICE_CROSS_SELLING_GET_FILE_ERROR = `${BACKOFFICE}/CROSS_SELLING_GET_FILE_ERROR`;
export const BACKOFFICE_CROSS_SELLING_GET_REFERENCES_ERROR = `${BACKOFFICE}/CROSS_SELLING_GET_REFERENCES_ERROR`;
export const BACKOFFICE_CROSS_SELLING_GET_REFERENCES_FILE_ERROR = `${BACKOFFICE}/CROSS_SELLING_GET_REFERENCES_FILE_ERROR`;
export const BACKOFFICE_CROSS_SELLING_REPLACE_FILE_ERROR = `${BACKOFFICE}/CROSS_SELLING_REPLACE_FILE_ERROR`;
export const BACKOFFICE_CROSS_SELLING_UPLOAD_FILE_ERROR = `${BACKOFFICE}/CROSS_SELLING_UPLOAD_FILE_ERROR`;
export const BACKOFFICE_CROSS_SELLING_DELETE_FILE_ERROR = `${BACKOFFICE}/CROSS_SELLING_DELETE_FILE_ERROR`;
export const BACKOFFICE_CURRENT_PROMOTIONS_ERROR = `${BACKOFFICE}/CURRENT_PROMOTIONS_ERROR`;
//Maintenance plan
export const GET_MAINTENANCE_PLAN_ERROR = `${CATALOG}/GET_MAINTENANCE_PLAN_ERROR`;
export const GET_IAM_OPERATIONS_TREE_ERROR = `${CATALOG}/GET_IAM_OPERATIONS_TREE_ERROR`;
export const GET_IAM_OPERATIONS_FAMILIES_ERROR = `${CATALOG}/GET_IAM_OPERATIONS_FAMILIES_ERROR`;
export const GET_IAM_AVAILABLE_MODULES_ERROR = `${CATALOG}/GET_IAM_AVAILABLE_MODULES_ERROR`;
export const GET_IAM_SERVICE_PROPOSAL_ERROR = `${CATALOG}/GET_IAM_SERVICE_PROPOSAL_ERROR`;
export const GET_IAM_SERVICE_PROPOSAL_OPERATIONS_ERROR = `${CATALOG}/GET_IAM_SERVICE_PROPOSAL_OPERATIONS_ERROR`;
export const GET_IAM_SERVICE_CHECKLIST_ERROR = `${CATALOG}/GET_IAM_SERVICE_CHECKLIST_ERROR`;
export const GET_IAM_REPAIR_METHODS_ERROR = `${CATALOG}/GET_IAM_LIST_REPAIR_METHODS_ERROR`;
export const GET_IAM_REPAIR_METHOD_DETAIL_ERROR = `${CATALOG}/GET_IAM_REPAIR_METHOD_DETAIL_ERROR`;
//Maintenance values
export const GET_MAINTENANCE_VALUES_ERROR = `${CATALOG}/GET_MAINTENANCE_VALUES_ERROR`;
//IAM
export const GET_IAM_VEHICLE_BRANDS_ERROR = `${CATALOG}/GET_IAM_VEHICLE_BRANDS_ERROR`;
export const GET_IAM_VEHICLE_MODELS_ERROR = `${CATALOG}/GET_IAM_VEHICLE_MODELS_ERROR`;
export const GET_IAM_VEHICLE_VERSIONS_ERROR = `${CATALOG}/GET_IAM_VEHICLE_VERSIONS_ERROR`;
export const GET_EXPLODED_TREE_IAM_ERROR = `${CATALOG}/GET_EXPLODED_TREE_IAM_ERROR`;
export const GET_IAM_REFERENCES_ERROR = `${CATALOG}/IAM/GET_REFERENCES_ERROR`;
export const GET_IAM_TEXT_SEARCH_ERROR = `${CATALOG}/GET_IAM_TEXT_SEARCH_ERROR`;
export const GET_IAM_EQUIVALENT_REFERENCES_ERROR = `${CATALOG}/GET_IAM_EQUIVALENT_REFERENCES_ERROR`;
export const GET_IAM_REPAIR_METHODS_TREE_ERROR = `${CATALOG}/GET_IAM_REPAIR_METHODS_TREE_ERROR`;
//TIRES
export const VEHICLE_TIRES_ERROR = `${TIRES}/VEHICLE_TIRES_ERROR`;
export const TIRES_SEARCH_DIMENSION_ERROR = `${TIRES}/TIRES_SEARCH_DIMENSION_ERROR`;
export const TIRES_SEARCH_PRODUCTS_ERROR = `${TIRES}/TIRES_SEARCH_PRODUCTS_ERROR`;
export const GET_IAM_TIRES_ERROR = `${TIRES}/GET_IAM_TIRES_ERROR`;
export const GET_TIRE_BRAND_CATEGORIES_ERROR = `${TIRES}/GET_TIRE_BRAND_CATEGORIES_ERROR`;
// DMS
export const GET_AVAILABLE_DMS_SERVICES_ERROR = `${DMS}/GET_AVAILABLE_DMS_SERVICES_ERROR`;
export const GET_REPAIR_ORDER_ERROR = `${DMS}/GET_REPAIR_ORDER_ERROR`;
export const SEND_ESTIMATE_TO_DMS_ERROR = `${DMS}/SEND_ESTIMATE_TO_DMS_ERROR`;
// ORDER_LIST
export const UPLOAD_ORDER_LIST_REFERENCES_ERROR_API_CALL = `${ORDER_LIST}/UPLOAD_ORDER_LIST_REFERENCES_ERROR_API_CALL`;
export const UPLOAD_ORDER_LIST_REFERENCES_ERROR_TECHNICAL = `${ORDER_LIST}/UPLOAD_ORDER_LIST_REFERENCES_ERROR_TECHNICAL`;
export const UPLOAD_ORDER_LIST_REFERENCES_FILE_ERROR = `${ORDER_LIST}/UPLOAD_ORDER_LIST_REFERENCES_FILE_ERROR`;
export const UPLOAD_ORDER_LIST_REFERENCES_FILE_ERROR_99_REFERENCES = `${ORDER_LIST}/UPLOAD_ORDER_LIST_REFERENCES_FILE_ERROR_99_REFERENCES`;
// MY_STORE
// BUNDLES
export const GET_MY_STORE_BUNDLES_ERROR = `${MY_STORE}/GET_MY_STORE_BUNDLES_ERROR`;
// TIRES
export const GET_MY_STORE_TIRES_ERROR = `${MY_STORE}/GET_MY_STORE_TIRES_ERROR`;
const allResponseTypes = [
    // Responses
    COMMAND_PROCESSING_RESPONSE,
    COMMAND_PROCESSING_RESPONSE_WITH_DATA,
    QUERY_SUCCESS_RESPONSE,
    QUERY_NO_DATA_RESPONSE,
    QUERY_INVALID_RESPONSE,
    QUERY_ERROR_RESPONSE,
    // Clarion
    GET_CLARION_LINK_RESPONSE,
    // DataHub
    SEARCH_VEHICLE_RESPONSE,
    SEARCH_VEHICLE_EXPLODED_VIEW_COUNT_RESPONSE,
    SEARCH_VEHICLE_EXPLODED_VIEW_RESPONSE,
    GET_VEHICLE_BRANDS_RESPONSE,
    GET_VEHICLE_ENGINES_RESPONSE,
    GET_VEHICLE_SEARCH_HISTORY_RESPONSE,
    GET_VEHICLE_CATEGORY_IMAGES_RESPONSE,
    EXPLODED_TREE_RESPONSE,
    GET_PLATE_DETAIL_RESPONSE,
    GET_LEVEL3_DETAILS_RESPONSE,
    GET_REFERENCE_DETAILS_RESPONSE,
    GET_REUSE_STOCK_RESPONSE,
    GET_PLATE_REFERENCES_RESPONSE,
    GET_REFERENCES_STOCKS_RESPONSE,
    CHECK_IF_STOCKS_ARE_UP_TO_DATE_RESPONSE,
    GET_REFERENCES_PRICE_RESPONSE,
    GET_REFERENCES_REPAIR_PRICE_RESPONSE,
    GET_REFERENCES_DISCOUNTS_RESPONSE,
    REMOVE_REFERENCES_DISCOUNTS_RESPONSE,
    GET_MARKETING_REFERENCES_RESPONSE,
    GET_PLATES_FOR_REFERENCE_RESPONSE,
    GET_LABOR_TIMES_RESPONSE,
    GET_USER_PROFILE_RESPONSE,
    GET_DELEGATION_CONNECT_AS_RESPONSE,
    NOTIFY_ABOUT_DELEGATION_DISCONNECT,
    GET_DELEGATION_DISCONNECT_FROM_RESPONSE,
    GET_POSSIBLE_SELLERS_TO_DELEGATE_RESPONSE,
    GET_POSSIBLE_BUYERS_TO_DELEGATE_RESPONSE,
    GET_USER_BASKET_RESPONSE,
    GET_USER_SELLERS_RESPONSE,
    GET_USER_SELLERS_PENDING_WARNING,
    GET_BUILD_VERSIONS_RESPONSE,
    GET_APPLICATION_CONFIG_RESPONSE,
    CHECKOUT_ORDER_RESPONSE,
    GET_ORDER_LIST_RESPONSE,
    GET_EXTERNAL_ORDER_LIST_RESPONSE,
    GET_ORDER_BY_ID_RESPONSE,
    RE_ADD_ORDERED_REFERENCES_TO_BASKET_RESPONSE,
    ADD_BASKET_OTHER_REFERENCE_RESPONSE,
    UPLOAD_BASKET_REFERENCES_FILE_RESPONSE,
    GET_LATEST_ESTIMATE_RESPONSE,
    ADD_ESTIMATE_KNOWN_REFERENCE_RESPONSE,
    ADD_ESTIMATE_TIRE_RESPONSE,
    GET_ESTIMATE_BY_ID_RESPONSE,
    GET_ESTIMATE_HISTORY_RESPONSE,
    GET_ESTIMATE_SETTINGS_RESPONSE,
    ESTIMATE_CREATED_RESPONSE,
    AUTOCOMPLETE_BUNDLES_SEARCH_ERROR,
    GET_GARAGE_INFO_RESPONSE,
    GARAGE_EMAIL_UPDATE,
    GET_FULL_TEXT_AUTOCOMPLETE_RESPONSE,
    GET_TEXT_SEARCH_RESPONSE,
    GET_REFERENCE_TEXT_SEARCH_RESPONSE,
    GET_PICTURE_RESPONSE,
    SEND_EMAIL_FROM_GARAGE_RESPONSE,
    SEND_EMAIL_TO_ASSISTANCE_RESPONSE,
    SEND_EMAIL_DOCUMENTATION_ALERT_MOTRIO_RESPONSE,
    GET_UNIVERSAL_PRODUCTS_TREE_RESPONSE,
    GET_UNIVERSAL_PRODUCTS_RESPONSE,
    GET_VEHICLE_TECHNICAL_CRITERIA_RESPONSE,
    GET_IAM_VEHICLE_TECHNICAL_CRITERIA_RESPONSE,
    BACKOFFICE_ELECTRONIC_PARTS_REPAIR_UPLOAD_REFERENCES_FILE_RESPONSE,
    BACKOFFICE_ELECTRONIC_PARTS_REPAIR_REPLACE_REFERENCES_FILE_RESPONSE,
    BACKOFFICE_ELECTRONIC_PARTS_REPAIR_GET_FILE_RESPONSE,
    BASKET_ATTACH_OTHER_REFERENCE_CAR_NONCOMPATIBLE_RESPONSE,
    BASKET_ATTACH_OTHER_REFERENCE_CAR_RESPONSE,
    BACKOFFICE_DELIVERY_LEAD_TIME_RESPONSE,
    BACKOFFICE_DELIVERY_LEAD_TIME_UPDATE_RESPONSE,
    BACKOFFICE_PROMOTION_RESPONSE,
    BACKOFFICE_FULL_PROMOTION_RESPONSE,
    BACKOFFICE_DASHBOARD_PROMOTIONS_RESPONSE,
    BACKOFFICE_SAVE_PROMOTION_RESPONSE,
    BACKOFFICE_ADJUST_PROMOTION_BY_R1_RESPONSE,
    BACKOFFICE_PUBLISH_PROMOTION_RESPONSE,
    BACKOFFICE_ARCHIVE_PROMOTION_RESPONSE,
    BACKOFFICE_DELETE_PROMOTION_RESPONSE,
    BACKOFFICE_PROMOTION_BANNERS_RESPONSE,
    BACKOFFICE_PROMOTION_BANNERS_UPDATE_RESPONSE,
    BACKOFFICE_REFERENCES_DISCOUNTS_RESPONSE,
    BACKOFFICE_PROMOTION_UPLOAD_REFERENCES_FILE_RESPONSE,
    BACKOFFICE_PRODUCTS_VALIDATION_RESPONSE,
    BACKOFFICE_PROMOTION_UPLOAD_TARGETED_AUDIENCE_FILE_RESPONSE,
    BACKOFFICE_NUMBER_OF_NOT_YET_ADJUSTED_PROMOTIONS_RESPONSE,
    BACKOFFICE_INFORMATION_RESPONSE,
    BACKOFFICE_INFORMATIONS_RESPONSE,
    BACKOFFICE_SAVE_INFORMATION_RESPONSE,
    BACKOFFICE_SAVE_INFORMATION_TEMPLATE_RESPONSE,
    BACKOFFICE_PUBLISH_INFORMATION_RESPONSE,
    BACKOFFICE_ARCHIVE_INFORMATION_RESPONSE,
    BACKOFFICE_INFORMATION_BANNERS_RESPONSE,
    BACKOFFICE_INFORMATION_BANNER_UPDATE,
    BACKOFFICE_CROSS_SELLING_GET_FILE_RESPONSE,
    BACKOFFICE_CROSS_SELLING_GET_FILE_PROGRESS_RESPONSE,
    BACKOFFICE_CROSS_SELLING_GET_REFERENCES_RESPONSE,
    BACKOFFICE_CROSS_SELLING_GET_REFERENCES_FILE_RESPONSE,
    BACKOFFICE_CROSS_SELLING_UPLOAD_FILE_RESPONSE,
    BACKOFFICE_CROSS_SELLING_DELETE_FILE_RESPONSE,
    BACKOFFICE_CURRENT_PROMOTIONS_RESPONSE,
    BACKOFFICE_UPDATE_CURRENT_PROMOTIONS_RESPONSE,
    BACKOFFICE_GET_RETURNS_FEATURE_SETTINGS_RESPONSE,
    BACKOFFICE_SAVE_RETURNS_FEATURE_SETTINGS_RESPONSE,
    GET_MAINTENANCE_PLAN_RESPONSE,
    GET_MAINTENANCE_VALUES_RESPONSE,
    // IAM
    GET_IAM_VEHICLE_BRANDS_RESPONSE,
    GET_IAM_VEHICLE_MODELS_RESPONSE,
    GET_IAM_VEHICLE_VERSIONS_RESPONSE,
    GET_EXPLODED_TREE_IAM_RESPONSE,
    GET_IAM_REFERENCES_RESPONSE,
    GET_IAM_LABOR_TIMES_RESPONSE,
    GET_LABOR_TIME_IDS_BY_GENERIC_PARTS_RESPONSE,
    GET_IAM_TECHNICAL_DATA_TREE_RESPONSE,
    GET_IAM_TECHNICAL_DATA_DETAILS_RESPONSE,
    GET_IAM_TEXT_SEARCH_RESPONSE,
    GET_IAM_EQUIVALENT_REFERENCES_RESPONSE,
    GET_IAM_OPERATIONS_TREE_RESPONSE,
    GET_IAM_OPERATIONS_FAMILIES_RESPONSE,
    GET_IAM_AVAILABLE_MODULES_RESPONSE,
    GET_IAM_SERVICE_PROPOSAL_RESPONSE,
    GET_IAM_SERVICE_PROPOSAL_OPERATIONS_RESPONSE,
    GET_IAM_SERVICE_CHECKLIST_RESPONSE,
    GET_IAM_REPAIR_METHODS_RESPONSE,
    GET_IAM_REPAIR_METHOD_DETAIL_RESPONSE,
    //TIRES
    VEHICLE_TIRES_RESPONSE,
    TIRES_FULLTEXT_SEARCH_RESPONSE,
    TIRES_SEARCH_DIMENSIONS_RESPONSE,
    TIRES_SEARCH_UNIQUE_DIMENSIONS_RESPONSE,
    TIRES_SEARCH_PRODUCTS_RESPONSE,
    GET_IAM_TIRES_RESPONSE,
    GET_TIRE_BRAND_CATEGORIES_RESPONSE,
    // DMS
    GET_AVAILABLE_DMS_SERVICES_RESPONSE,
    SEND_ESTIMATE_TO_DMS_RESPONSE,
    // Order list
    UPLOAD_ORDER_LIST_REFERENCES_RESPONSE,
    UPLOAD_ORDER_LIST_REFERENCES_FILE_RESPONSE,
    // MY_STORE
    GET_MY_STORE_BUNDLES_RESPONSE,
    GET_MY_STORE_TIRES_RESPONSE,
    // Errors
    // DataHub
    SEARCH_VEHICLE_ERROR,
    GET_REFERENCE_DETAILS_ERROR,
    GET_REUSE_STOCK_ERROR,
    GET_VEHICLE_BRANDS_ERROR,
    GET_VEHICLE_ENGINES_ERROR,
    GET_VEHICLE_SEARCH_HISTORY_ERROR,
    GET_LEVEL3_ERROR,
    GET_PLATE_REFERENCES_ERROR,
    GET_REFERENCES_STOCKS_ERROR,
    GET_REFERENCES_PRICE_ERROR,
    GET_REFERENCES_REPAIR_PRICE_ERROR,
    GET_MARKETING_REFERENCES_ERROR,
    GET_PLATES_FOR_REFERENCE_ERROR,
    GET_LABOR_TIMES_ERROR,
    GET_LABOR_TIME_IDS_BY_GENERIC_PARTS_ERROR,
    GET_USER_BASKET_ERROR,
    GET_DELEGATION_CONNECT_AS_RESPONSE_ERROR,
    GET_POSSIBLE_SELLERS_TO_DELEGATE_RESPONSE_ERROR,
    GET_POSSIBLE_BUYERS_TO_DELEGATE_RESPONSE_ERROR,
    GET_USER_SELLERS_ERROR,
    GET_ORDER_LIST_ERROR,
    GET_ORDER_BY_ID_ERROR,
    GET_EXTERNAL_ORDER_LIST_ERROR,
    GET_BASKET_REFERENCES_STOCKS_ERROR,
    ADD_ESTIMATE_KNOWN_LABOR_TIME_ERROR,
    UPLOAD_BASKET_REFERENCES_FILE_ERROR_99_REFERENCES,
    UPLOAD_BASKET_REFERENCES_FILE_ERROR_TECHNICAL,
    UPLOAD_BASKET_REFERENCES_FILE_ERROR_API_CALL,
    GET_LATEST_ESTIMATE_ERROR,
    GET_ESTIMATE_BY_ID_ERROR,
    GET_ESTIMATE_HISTORY_ERROR,
    GET_ESTIMATE_SETTINGS_ERROR,
    GET_GARAGE_INFO_ERROR,
    GET_FULL_TEXT_AUTOCOMPLETE_ERROR,
    GET_TEXT_SEARCH_ERROR,
    GET_REFERENCE_TEXT_SEARCH_ERROR,
    GET_PICTURE_ERROR,
    SEND_EMAIL_FROM_GARAGE_ERROR,
    SEND_EMAIL_TO_ASSISTANCE_ERROR,
    SEND_EMAIL_DOCUMENTATION_ALERT_MOTRIO_ERROR,
    GET_UNIVERSAL_PRODUCTS_TREE_ERROR,
    GET_UNIVERSAL_PRODUCTS_ERROR,
    GET_VEHICLE_TECHNICAL_CRITERIA_ERROR,
    BACKOFFICE_ELECTRONIC_PARTS_REPAIR_UPLOAD_REFERENCES_FILE_ERROR,
    BACKOFFICE_ELECTRONIC_PARTS_REPAIR_GET_FILE_ERROR,
    BACKOFFICE_ELECTRONIC_PARTS_REPAIR_REPLACE_REFERENCES_FILE_ERROR,
    BASKET_ATTACH_OTHER_REFERENCE_CHECK_ERROR,
    BACKOFFICE_DELIVERY_LEAD_TIME_ERROR,
    BACKOFFICE_PROMOTION_ERROR,
    BACKOFFICE_FULL_PROMOTION_ERROR,
    BACKOFFICE_PROMOTION_BANNERS_ERROR,
    BACKOFFICE_REFERENCES_DISCOUNTS_ERROR,
    BACKOFFICE_PROMOTION_UPLOAD_REFERENCES_FILE_ERROR,
    BACKOFFICE_PROMOTION_UPLOAD_TARGETED_AUDIENCE_FILE_ERROR,
    BACKOFFICE_INFORMATION_ERROR,
    BACKOFFICE_INFORMATIONS_ERROR,
    BACKOFFICE_SAVE_INFORMATION_ERROR,
    BACKOFFICE_PUBLISH_INFORMATION_ERROR,
    BACKOFFICE_SAVE_INFORMATION_TEMPLATE_ERROR,
    BACKOFFICE_INFORMATION_BANNERS_ERROR,
    BACKOFFICE_CROSS_SELLING_GET_FILE_ERROR,
    BACKOFFICE_CROSS_SELLING_GET_REFERENCES_ERROR,
    BACKOFFICE_CROSS_SELLING_GET_REFERENCES_FILE_ERROR,
    BACKOFFICE_CROSS_SELLING_REPLACE_FILE_ERROR,
    BACKOFFICE_CROSS_SELLING_UPLOAD_FILE_ERROR,
    BACKOFFICE_CROSS_SELLING_DELETE_FILE_ERROR,
    BACKOFFICE_CURRENT_PROMOTIONS_ERROR,
    GET_MAINTENANCE_PLAN_ERROR,
    GET_MAINTENANCE_VALUES_ERROR,
    GET_IAM_OPERATIONS_TREE_ERROR,
    GET_IAM_OPERATIONS_FAMILIES_ERROR,
    GET_IAM_AVAILABLE_MODULES_ERROR,
    GET_IAM_SERVICE_PROPOSAL_ERROR,
    GET_IAM_SERVICE_PROPOSAL_OPERATIONS_ERROR,
    GET_IAM_SERVICE_CHECKLIST_ERROR,
    GET_IAM_REPAIR_METHODS_ERROR,
    GET_IAM_REPAIR_METHOD_DETAIL_ERROR,
    //IAM
    GET_IAM_VEHICLE_BRANDS_ERROR,
    GET_IAM_VEHICLE_MODELS_ERROR,
    GET_IAM_VEHICLE_VERSIONS_ERROR,
    GET_EXPLODED_TREE_IAM_ERROR,
    GET_IAM_REFERENCES_ERROR,
    GET_IAM_LABOR_TIMES_ERROR,
    GET_IAM_TECHNICAL_DATA_TREE_ERROR,
    GET_IAM_TECHNICAL_DATA_DETAILS_ERROR,
    GET_IAM_TEXT_SEARCH_ERROR,
    GET_IAM_EQUIVALENT_REFERENCES_ERROR,
    GET_IAM_REPAIR_METHODS_TREE_ERROR,
    //TIRES
    VEHICLE_TIRES_ERROR,
    GET_IAM_TIRES_ERROR,
    // Application PNE
    TIRES_SEARCH_DIMENSION_ERROR,
    TIRES_SEARCH_PRODUCTS_ERROR,
    GET_TIRE_BRAND_CATEGORIES_ERROR,
    //DMS
    GET_AVAILABLE_DMS_SERVICES_ERROR,
    GET_REPAIR_ORDER_ERROR,
    SEND_ESTIMATE_TO_DMS_ERROR,
    // Order list
    UPLOAD_ORDER_LIST_REFERENCES_ERROR_API_CALL,
    UPLOAD_ORDER_LIST_REFERENCES_ERROR_TECHNICAL,
    UPLOAD_ORDER_LIST_REFERENCES_FILE_ERROR,
    UPLOAD_ORDER_LIST_REFERENCES_FILE_ERROR_99_REFERENCES,
    // MY_STORE
    GET_MY_STORE_BUNDLES_ERROR,
    GET_MY_STORE_TIRES_ERROR,
];

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TireItem } from '@1po/1po-bff-fe-spec/generated/tire/response/TireSearchResponse';
import { RootState } from 'app/AppStore';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import Filter from 'components/Filter';
import {
  FILTER_BRAND,
  FILTER_DELIVERY,
  FILTER_PRICES,
  getFiltersMap,
  getStockFilterOptions,
  RANGE_FILTER_TYPE,
  RangeFacetLocal,
  TextFacetLocal,
  TextFilterItem,
} from 'components/Filter/Filter.types';
import { LIFECYCLE_W1, LIFECYCLE_W3, LIFECYCLE_W4, TIRE } from 'domains/catalog/Catalog.types';
import { getLoadedPrices, getStocksMap } from 'domains/references';
import { useFetchPrices, useFetchTireStocks } from 'domains/references/References.requests';
import { useFetchTireBrandCategories } from 'domains/tires/Tire.requests';
import {
  getTireCatalogFilters,
  getTireSearchResult,
  resetTireFilters,
  resetTireTextFilter,
  setTireRangeFilter,
  setTireTextFilter,
  setTireTextFilters,
} from 'domains/tires/Tire.store';
import { GarageView, SparePartsViewType } from 'domains/user';
import {
  getFilteredTires,
  getTireDeliveryFilterOptions,
  getUpdatedTireRangeFilters,
  getUpdatedTireTextFilters,
} from 'pages/TiresPage/TireReferencesSection/TireReferencesContainer/TireFilterUtils';
import { TireReferenceCardsContainer } from 'pages/TiresPage/TireReferencesSection/TireReferencesContainer/TireReferenceCardsContainer';
import { Box, CenteredSpin, CenterFlex, Flex, MarginBox } from 'UI';
import { FOUND, getData, isLoading, LOADING, NOT_FOUND, useLarge } from 'utils';

interface MatchingTiresInterface {
  exactMatchTires: TireItem[];
  nonExactMatchTires: TireItem[];
}

const getMatchingTires = (tires: TireItem[]): MatchingTiresInterface => {
  return tires?.reduce(
    (acc: MatchingTiresInterface, tire) => {
      tire.isExactSearchMatch ? acc.exactMatchTires.push(tire) : acc.nonExactMatchTires.push(tire);
      return acc;
    },
    { exactMatchTires: [], nonExactMatchTires: [] },
  );
};

const TireReferencesLoader = () => (
  <CenterFlex>
    <Box height={200}>
      <CenteredSpin />
    </Box>
  </CenterFlex>
);

const TireReferencesError = () => {
  const { t } = useTranslation();
  return (
    <CenterFlex>
      <Box height={210}>
        <ErrorWithLabel
          label={t(
            'catalog.tires.references.backend_error',
            'References for the given tire parameters are temporarily unavailable, please try again later.',
          )}
        />
      </Box>
    </CenterFlex>
  );
};

interface TireReferencesProps {
  searchParamsBase64: string;
  sparePartsView: SparePartsViewType;
  setNumOfProducts: (num: number) => void;
  initialTextFilters?: TextFilterItem[];
}

export const TireReferencesContainer = ({
  searchParamsBase64,
  sparePartsView,
  setNumOfProducts,
  initialTextFilters,
}: TireReferencesProps) => {
  const { t } = useTranslation();
  const large = useLarge();
  const dispatch = useDispatch();
  const filters = useSelector(getTireCatalogFilters);
  const catalogTires = useSelector((state: RootState) =>
    getTireSearchResult(state, {
      searchParamsBase64,
    }),
  );
  const allTires = useMemo(() => catalogTires?.data?.tires ?? [], [catalogTires]);
  const [showPriceless, setShowPriceless] = useState<boolean>(false);
  const refsForStocks = allTires
    .filter((tire) => !(tire.lifeCycle === LIFECYCLE_W1 || tire.lifeCycle === LIFECYCLE_W4))
    .map((item) => item.partNumber);
  const refsForPrices = allTires.filter((tire) => tire.lifeCycle !== LIFECYCLE_W4).map((item) => item.partNumber);
  const stocks = useSelector((state: RootState) => getStocksMap(state, refsForStocks ?? []));
  const prices = useSelector((state: RootState) => getLoadedPrices(state, refsForPrices ?? []));
  const brandCategoriesMap = useFetchTireBrandCategories();
  const stockStatuses = useMemo(() => [...new Set([...stocks.values()].map((s) => s?.searchStatus))], [stocks]);
  const somePriceMissing = prices.some((p) => isLoading(p.prices));
  const someStockLoading = stockStatuses.some((sp) => isLoading(sp));
  const [someStockMissingFirstLoad, setSomeStockMissingFirstLoad] = useState(true);
  const availableTires = useMemo(() => {
    return allTires.filter((tire) => {
      const stock = stocks.get(tire.partNumber);
      const lifeCycleW3 = tire.lifeCycle === LIFECYCLE_W3;
      const stockSearchStatus = stock?.searchStatus;
      const warehouses = stock?.data?.warehouses ?? [];
      return !(
        lifeCycleW3 &&
        ((stockSearchStatus === FOUND && warehouses.filter((w) => w.type === 'LOCAL').length === 0) ||
          stockSearchStatus === NOT_FOUND)
      );
    });
  }, [allTires, stocks]);
  const priceForAvailable = prices.filter((p) => availableTires.find((a) => a.partNumber === p.reference));
  const [textFilters, setTextFilters] = useState<TextFacetLocal[]>(catalogTires?.data?.textFilters ?? []);
  const pricesRange: [number, number] | undefined = useMemo(() => {
    const pricesValues = priceForAvailable.map((p) => {
      const price = getData(p);
      return sparePartsView === GarageView
        ? Number(getData(price?.prices)?.garageView?.vatExcludedPrice ?? 0)
        : Number(getData(price?.prices)?.clientView?.recommendedPriceVatIncluded ?? 0);
    });
    const productPrices = pricesValues.filter((p) => p > 0);
    return productPrices.length > 0 ? [Math.min(...productPrices), Math.max(...productPrices)] : undefined;
  }, [priceForAvailable, sparePartsView]);

  useFetchTireStocks(refsForStocks ?? []);
  useFetchPrices(refsForPrices ?? []);

  const pricesFacet: RangeFacetLocal = {
    id: FILTER_PRICES,
    type: RANGE_FILTER_TYPE,
    min: pricesRange ? pricesRange[0] : 0,
    max: pricesRange ? pricesRange[1] : 0,
    numberOfItems: availableTires.length ?? 0,
    active: true,
  };
  const [rangeFilters, setRangeFilters] = useState<RangeFacetLocal[]>([pricesFacet]);

  const filteredInitialTextFilters: TextFilterItem[] = useMemo(() => {
    return (
      initialTextFilters
        ?.map(({ id, item }) => {
          if (id !== FILTER_BRAND) {
            return { id, item };
          }

          const isFavoriteBrandPresentInFilteredBrands = availableTires.find((tire) => tire.brandId === item);

          if (isFavoriteBrandPresentInFilteredBrands) {
            return { id, item };
          }
          return undefined;
        })
        .filter((item): item is TextFilterItem => item !== undefined) ?? []
    );
  }, [availableTires, initialTextFilters]);

  useEffect(() => {
    if (someStockMissingFirstLoad && stockStatuses.length > 0) {
      setSomeStockMissingFirstLoad(stockStatuses.some((sp) => isLoading(sp)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockStatuses]);

  useEffect(() => {
    setSomeStockMissingFirstLoad(true);
  }, [searchParamsBase64]);

  const filteredTires = useMemo(
    () =>
      getFilteredTires(availableTires, filters, prices, sparePartsView, showPriceless, stocks, brandCategoriesMap, t),
    [availableTires, filters, prices, stocks, t, brandCategoriesMap, sparePartsView, showPriceless],
  );

  const { exactMatchTires, nonExactMatchTires } = getMatchingTires(filteredTires);

  const initialTireTextFiltersJson = JSON.stringify(catalogTires?.data?.textFilters);
  const textFiltersJson = JSON.stringify(textFilters);
  useEffect(() => {
    const initialTireTextFilters = catalogTires?.data?.textFilters ?? [];
    const updTextFilters = getUpdatedTireTextFilters(
      initialTireTextFilters,
      stocks,
      availableTires,
      brandCategoriesMap,
      t,
    );
    if (stockStatuses.length > 0) {
      const stocksFilterOptions = getStockFilterOptions('TIRES', true);
      const deliveryFilterOptions = getTireDeliveryFilterOptions(stocksFilterOptions, stocks, availableTires, t);
      const hasDeliveryFilter = !!updTextFilters.find((ft) => ft.id === FILTER_DELIVERY);
      if (hasDeliveryFilter) {
        const updatedTextFiltersWithDelivery: TextFacetLocal[] = updTextFilters.map((item) =>
          item.id === FILTER_DELIVERY && deliveryFilterOptions ? deliveryFilterOptions : item,
        );
        const deliveryFilterSelection = filters.textFilters.get(FILTER_DELIVERY);
        const missingSelectedDeliveryOptions =
          deliveryFilterSelection?.filter((sel) => {
            const updatedDelivery = deliveryFilterOptions.items.find((item) => item.label === sel);
            return updatedDelivery === undefined || updatedDelivery.numberOfItems === 0;
          }) ?? [];
        if (missingSelectedDeliveryOptions.length === 1) {
          dispatch(resetTireTextFilter({ filterOption: FILTER_DELIVERY }));
        }
        setTextFilters(updatedTextFiltersWithDelivery);
      } else {
        setTextFilters(updTextFilters.concat(deliveryFilterOptions));
      }
    } else {
      setTextFilters(updTextFilters);
    }
    setRangeFilters(getUpdatedTireRangeFilters(rangeFilters, filteredTires));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textFiltersJson, initialTireTextFiltersJson]);

  useEffect(() => {
    if (!someStockMissingFirstLoad && !someStockLoading && initialTextFilters) {
      dispatch(setTireTextFilters(filteredInitialTextFilters));
    }
    // eslint-disable-next-line
  }, [dispatch, someStockMissingFirstLoad, someStockLoading]);

  const priceForAvailableJson = JSON.stringify(priceForAvailable);
  useEffect(() => {
    setRangeFilters([pricesFacet]);
    // eslint-disable-next-line
  }, [somePriceMissing, priceForAvailableJson]);

  useEffect(() => {
    setNumOfProducts(filteredTires.length);
    // eslint-disable-next-line
  }, [filteredTires.length]);

  return (
    <Flex direction={large ? 'row' : 'column'}>
      <Flex direction={'column'} size={0}>
        <MarginBox ml={large ? 0 : 15} my={large ? 0 : 15}>
          <Filter
            filters={filters}
            textFilters={textFilters}
            rangeFilters={rangeFilters}
            setTextFilters={(id, item) => dispatch(setTireTextFilter({ id, item }))}
            setRangeFilters={(id, range) => dispatch(setTireRangeFilter({ id, range }))}
            resetFilters={() => dispatch(resetTireFilters())}
            loading={catalogTires?.searchStatus === LOADING || someStockLoading}
            facetsMap={getFiltersMap(t)}
            usePrice={{
              showPriceless,
              setShowPriceless,
            }}
            requiredMin={0}
            type={TIRE}
          />
        </MarginBox>
      </Flex>
      <DataContainer data={catalogTires?.searchStatus} Loading={TireReferencesLoader} ErrorState={TireReferencesError}>
        <Flex direction={'column'}>
          {exactMatchTires?.length > 0 && (
            <TireReferenceCardsContainer
              searchParamsBase64={searchParamsBase64}
              tires={exactMatchTires}
              sparePartsView={sparePartsView}
              singleSection={nonExactMatchTires && nonExactMatchTires.length === 0}
              initShowCount={5}
            />
          )}
          {nonExactMatchTires?.length > 0 && (
            <TireReferenceCardsContainer
              title={t('catalog.tires.similar.products.info.msg', 'These products could also match your needs')}
              searchParamsBase64={searchParamsBase64}
              tires={nonExactMatchTires}
              sparePartsView={sparePartsView}
              singleSection={exactMatchTires && exactMatchTires.length === 0}
              initShowCount={4}
              compact
            />
          )}
        </Flex>
      </DataContainer>
    </Flex>
  );
};

import { push, routerMiddleware } from 'react-router-redux';
import { AppContextSagas } from 'domains/appContext/AppContext.saga';
import AppContextReducer from 'domains/appContext/AppContext.store';
import { AuthSagas } from 'domains/auth/Auth.saga';
import AuthReducer from 'domains/auth/Auth.store';
import { BasketSagas } from 'domains/basket/Basket.saga';
import BasketReducer from 'domains/basket/Basket.store';
import { CatalogSagas } from 'domains/catalog/Catalog.saga';
import CatalogReducer from 'domains/catalog/Catalog.store';
import { ClarionSagas } from 'domains/clarion/Clarion.saga';
import ClarionReducer from 'domains/clarion/Clarion.store';
import { ClientLogSagas } from 'domains/clientLog/ClientLog.saga';
import { CrossSellingSagas } from 'domains/crossSelling/CrossSelling.saga';
import CrossSellingReducer from 'domains/crossSelling/CrossSelling.store';
import { DeliveryLeadTimeSagas } from 'domains/deliveryLeadTime/DeliveryLeadTime.saga';
import DeliveryLeadTimeReducer from 'domains/deliveryLeadTime/DeliveryLeadTime.store';
import { ElectronicPartsRepairSagas } from 'domains/electronicPartsRepair/ElectronicPartsRepair.saga';
import ElectronicPartsRepairReducer from 'domains/electronicPartsRepair/ElectronicPartsRepair.store';
import { EmailSagas } from 'domains/email/Email.saga';
import EmailReducer from 'domains/email/Email.store';
import { ErrorsSagas } from 'domains/errors/Errors.saga';
import { EstimateSagas } from 'domains/estimate/Estimate.saga';
import EstimateReducer from 'domains/estimate/Estimate.store';
import { ExternalBasketSagas } from 'domains/externalBasket/ExternalBasket.saga';
import ExternalBasketReducer from 'domains/externalBasket/ExternalBasket.store';
import FirstHelpReducer from 'domains/firstHelp/FirstHelp.store';
import { GarageSagas } from 'domains/garage/Garage.saga';
import GarageReducer from 'domains/garage/Garage.store';
import { InformationSagas } from 'domains/information/Information.saga';
import InformationReducer from 'domains/information/Information.store';
import { LaborTimeSagas } from 'domains/laborTime/LaborTime.saga';
import LaborTimeReducer from 'domains/laborTime/LaborTime.store';
import { MaintenancePlanSagas } from 'domains/maintenancePlan/MaintenancePlan.saga';
import MaintenancePlanReducer from 'domains/maintenancePlan/MaintenancePlan.store';
import { MaintenanceValuesSagas } from 'domains/maintenanceValues/MaintenanceValues.saga';
import MaintenanceValuesReducer from 'domains/maintenanceValues/MaintenanceValues.store';
import { MyStoreSagas } from 'domains/myStore/MyStore.saga';
import MyStoreReducer from 'domains/myStore/MyStore.store';
import { OrderSagas } from 'domains/order/Order.saga';
import OrderReducer from 'domains/order/Order.store';
import { OrderListSagas } from 'domains/orderList/OrderList.saga';
import OrderListReducer from 'domains/orderList/OrderList.store';
import { OrderValidationSagas } from 'domains/orderValidation/OrderValidation.saga';
import OrderValidationReducer from 'domains/orderValidation/OrderValidation.store';
import { PicturesSagas } from 'domains/pictures/Pictures.saga';
import PicturesReducer from 'domains/pictures/Pictures.store';
import { PromotionSagas } from 'domains/promotion/Promotion.saga';
import PromotionReducer from 'domains/promotion/Promotion.store';
import { R1NetworkSagas } from 'domains/r1Network/R1Network.saga';
import R1NetworkReducer from 'domains/r1Network/R1Network.store';
import { ReferencesSagas } from 'domains/references/References.saga';
import ReferencesReducer from 'domains/references/References.store';
import { TireSagas } from 'domains/tires/Tire.saga';
import TireReducer from 'domains/tires/Tire.store';
import { UrlSignaturesSagas } from 'domains/urlSignatures/UrlSignatures.saga';
import UrlSignaturesReducer from 'domains/urlSignatures/UrlSignatures.store';
import { UsersSagas } from 'domains/user/User.saga';
import UserReducer from 'domains/user/User.store';
import { socketMiddleware } from 'domains/webSockets/WebSocket.middleware';
import WebSocketReducer from 'domains/webSockets/WebSocket.store';
import { buildStore } from 'utils/domainStore/store';
import { browserHistory } from 'utils/history';

export function createAppStore() {
  const wsMiddleware = socketMiddleware();
  const browserMiddleware = routerMiddleware(browserHistory);
  return buildStore(
    {
      auth: AuthReducer,
      user: UserReducer,
      websockets: WebSocketReducer,
      catalog: CatalogReducer,
      basket: BasketReducer,
      order: OrderReducer,
      appContext: AppContextReducer,
      estimate: EstimateReducer,
      externalBasket: ExternalBasketReducer,
      firstHelp: FirstHelpReducer,
      references: ReferencesReducer,
      laborTime: LaborTimeReducer,
      orderValidation: OrderValidationReducer,
      pictures: PicturesReducer,
      electronicCarRepairs: ElectronicPartsRepairReducer,
      deliveryLeadTime: DeliveryLeadTimeReducer,
      promotion: PromotionReducer,
      information: InformationReducer,
      myStore: MyStoreReducer,
      garage: GarageReducer,
      crossSelling: CrossSellingReducer,
      urlSignature: UrlSignaturesReducer,
      clarion: ClarionReducer,
      email: EmailReducer,
      tires: TireReducer,
      maintenancePlan: MaintenancePlanReducer,
      maintenanceValues: MaintenanceValuesReducer,
      orderList: OrderListReducer,
      r1Network: R1NetworkReducer,
    },
    [
      AuthSagas,
      UsersSagas,
      CatalogSagas,
      ErrorsSagas,
      BasketSagas,
      OrderSagas,
      AppContextSagas,
      ClientLogSagas,
      EstimateSagas,
      ExternalBasketSagas,
      ElectronicPartsRepairSagas,
      LaborTimeSagas,
      OrderValidationSagas,
      OrderListSagas,
      PicturesSagas,
      DeliveryLeadTimeSagas,
      PromotionSagas,
      InformationSagas,
      MyStoreSagas,
      GarageSagas,
      ReferencesSagas,
      CrossSellingSagas,
      UrlSignaturesSagas,
      EmailSagas,
      TireSagas,
      ClarionSagas,
      MaintenancePlanSagas,
      MaintenanceValuesSagas,
      R1NetworkSagas,
    ],
    [wsMiddleware, browserMiddleware],
  );
}

export const globalStore = createAppStore();

export const redirect = (to: string): void => {
  globalStore.dispatch(push(to));
};

export type RootState = ReturnType<typeof globalStore.getState>;

import { FilterBy } from '@1po/1po-bff-fe-spec/generated/common/filter_and_sort/FilterBy';
import { SortBy } from '@1po/1po-bff-fe-spec/generated/common/filter_and_sort/SortBy';
import { FilterAndSortField } from '@1po/1po-bff-fe-spec/generated/order/request/model/FilterAndSortField';
import { ReturnReasonType } from '@1po/1po-bff-fe-spec/generated/order/request/ReturnEmailRequest';
import {
  CheckoutOverallStatus,
  OrderCheckoutResult,
} from '@1po/1po-bff-fe-spec/generated/order/response/CheckoutOrderResponse';
import {
  OrderItem,
  OrderReferenceItem,
  ReferenceBrandType,
  SubstituteReference,
} from '@1po/1po-bff-fe-spec/generated/order/response/GetDealerOrderPageResponse';
import { ExternalOrderItem } from '@1po/1po-bff-fe-spec/generated/order/response/model/ExternalOrderItem';
import { APP_NAMESPACE } from 'app/App.constants';
import { NO_DATA, SearchData } from 'utils';

export interface OrderState {
  inProgressOrders: InProgressOrdersState;
  checkoutStatus: CheckoutOrderStatus | null;
  externalOrders: ExternalOrdersState;
  allowedReturnsFeatures: Map<string, boolean>;
}

export interface CheckoutOrderStatus {
  placedOrders: OrderCheckoutResult[];
  failedOrders: OrderCheckoutResult[];
  ordersOverallStatus: CheckoutOverallStatus;
}

export interface InProgressOrdersState {
  orderMap: Map<string, SearchData<OrderItemLocal>>;
  orderIds: SearchData<string[]>;
  cursor: string | undefined;
  hasMore: boolean;
  search?: string;
  removeOldData: boolean; // workaround for table flickering
  isLoading: boolean;
  sort: SortOption | undefined;
  filters: Map<SortKey, FilterBy<FilterAndSortField>>;
  activeFilters: Map<FilterAndSortField, string[]>;
  pendingOrderIdsToReAddToBasket: string[];
}

export interface OrderItemLocal extends Omit<OrderItem, 'orderedReferences'> {
  orderedReferences: OrderReferenceItemLocal[];
}

export interface OrderReferenceItemLocal extends Omit<OrderReferenceItem, 'substitutes'> {
  requestReason: ReturnReasonType | undefined;
  qtyToReturn: number;
  substitutes?: SubstituteReferenceLocal[];
}

export interface OrderReturnItem {
  index: number;
  referenceNumber: string;
  parentReferenceNumber?: string;
  name?: string;
  brand?: ReferenceBrandType;
  supplier?: string;
  qtyToReturn: number;
  requestReason: ReturnReasonType | undefined;
}

export interface SubstituteReferenceLocal extends SubstituteReference {
  requestReason: ReturnReasonType | undefined;
  qtyToReturn: number;
}

export type SortKey = 'VEHICLE' | 'ORDER' | 'REFERENCE_NUMBER' | 'ORDER_MARK' | 'START_DATE' | 'END_DATE' | 'STATUS';

export type FilterKey = 'VEHICLE' | 'ORDER' | 'REFERENCE_NUMBER' | 'ORDER_MARK' | 'DATE' | 'STATUS';

export interface ExternalOrdersState {
  orders: ExternalOrderItem[] | NO_DATA;
}

export type SortOption = 'DATE_DESC' | 'DATE_ASC' | 'PRICE_DESC' | 'PRICE_ASC';

export const sortOptionsMapping: Map<SortOption, SortBy<FilterAndSortField>> = new Map([
  ['DATE_DESC', { field: 'DATE', sortOperation: 'DESC' }],
  ['DATE_ASC', { field: 'DATE', sortOperation: 'ASC' }],
  ['PRICE_DESC', { field: 'PRICE', sortOperation: 'DESC' }],
  ['PRICE_ASC', { field: 'PRICE', sortOperation: 'ASC' }],
]);

export const ORDER_NAMESPACE = `${APP_NAMESPACE}/ORDER`;
export const GET_ORDER_LIST_REQUEST = `${ORDER_NAMESPACE}/GARAGE_ORDER_LIST_REQUEST`;
export const GET_ORDER_BY_ID_REQUEST = `${ORDER_NAMESPACE}/GET_ORDER_BY_ID_REQUEST`;
export const CHECKOUT_ORDER_REQUEST = `${ORDER_NAMESPACE}/CHECKOUT_ORDER_REQUEST`;
export const GET_EXTERNAL_ORDER_LIST_REQUEST = `${ORDER_NAMESPACE}/EXTERNAL_ORDER_LIST_REQUEST`;
export const RE_ADD_ORDER_REFERENCES_TO_BASKET = `${ORDER_NAMESPACE}/RE_ADD_ORDER_REFERENCES_TO_BASKET`;
export const GET_IS_RETURNS_FEATURE_ENABLED_BY_SELLER_ID_REQUEST = `${ORDER_NAMESPACE}/GET_IS_RETURNS_FEATURE_ENABLED_BY_SELLER_ID_REQUEST`;

/* eslint-disable max-len */
import {
  Reference as DHReference,
  ReferenceBrandType,
} from '@1po/1po-bff-fe-spec/generated/catalog/references/dh/model/Reference';
import { ReuseReference } from '@1po/1po-bff-fe-spec/generated/catalog/references/dh/model/ReuseReference';
import {
  Reference as IAMReference,
  ReferenceSource,
} from '@1po/1po-bff-fe-spec/generated/catalog/references/iam/model/Reference';
import { ReferenceDiscount } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceDiscount';
import {
  ReferencePriceClientView,
  ReferencePriceGarageView,
} from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferencePrice';
import { ReferenceStock, Warehouse } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceStock';
import { ReferenceType } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceStockDetail';
import { DealerType } from '@1po/1po-bff-fe-spec/generated/user/response/SearchUserProfileResponse';
import { APP_NAMESPACE } from 'app/App.constants';
import { LOADING, NO_DATA, SEARCH_STATUS, SearchData } from 'utils';

export const REFERENCES_NAMESPACE = `${APP_NAMESPACE}/REFERENCES`;
export const CHECK_IF_STOCKS_ARE_UP_TO_DATE_REQUEST = `${REFERENCES_NAMESPACE}/CHECK_IF_STOCKS_ARE_UP_TO_DATE_REQUEST`;

export const GET_REFERENCES_PRICE_REQUEST = `${REFERENCES_NAMESPACE}/GET_REFERENCES_PRICE_REQUEST`;
export const GET_REFERENCES_STOCKS_REQUEST = `${REFERENCES_NAMESPACE}/GET_REFERENCES_STOCKS_REQUEST`;
export const GET_PLATES_FOR_REFERENCE_REQUEST = `${REFERENCES_NAMESPACE}/GET_PLATES_FOR_REFERENCE_REQUEST`;
export const GET_REFERENCES_DISCOUNTS_REQUEST = `${REFERENCES_NAMESPACE}/GET_REFERENCES_DISCOUNTS_REQUEST`;
export const GET_CROSS_SELLING_REFERENCES = `${REFERENCES_NAMESPACE}/GET_CROSS_SELLING_REFERENCES`;
export const GET_REFERENCES_REPAIR_PRICES_REQUEST = `${REFERENCES_NAMESPACE}/GET_REFERENCES_REPAIR_PRICES_REQUEST`;
export const GET_REUSE_STOCKS_REQUEST = `${REFERENCES_NAMESPACE}/GET_REUSE_STOCKS_REQUEST`;

export type LocalTabType = 'equivalent_references' | 'substitute_references' | 'linked_references' | 'kit' | undefined;

export interface ReferencesState {
  references: {
    dhReferences: ReferenceCatalog<DHReferenceLocal>;
    iamReferences: ReferenceCatalog<IAMReferenceLocal>;
  };
  prices: Map<string, ReferencePriceType | NO_DATA>; // Map<referenceNumber, object>
  repairPrices: Map<string, SearchData<string>>; // Map<referenceNumber, price>
  stockInfo: Map<string, SearchData<ReferenceStock>>; // Map<referenceNumber, object>
  stockUpdateChecks: Map<string, ReferenceStock[] | typeof LOADING>;
  referenceDiscounts: Map<string, SearchData<ReferenceDiscount>>; // Map<referenceNumber, object>
  referenceToReferenceType: Map<string, ReferenceType>;
  selectedTab: LocalTabType;
  reuseStock: Map<string, SearchData<ReuseReference>>;
  referenceToReferenceSource: Map<string, ReferenceSource>;
}

export interface DHReferenceLocal extends DHReference {
  detailStatus?: SEARCH_STATUS;
  referencePlateLinesStatus?: SEARCH_STATUS;
  source: 'dh';
  crossSelling?: string[] | NO_DATA;
}

export interface IAMReferenceLocal extends IAMReference {
  detailStatus?: SEARCH_STATUS;
  source: 'iam';
  referenceSource: ReferenceSource | undefined;
}

export type ReferenceLocal = DHReferenceLocal | IAMReferenceLocal;
export type Reference = DHReference | IAMReference;

export interface PlateReference {
  referenceNumber: string;
  plateId?: string;
  index?: number;
}

export interface ReferencePriceType {
  currency: string;
  garageView?: ReferencePriceGarageView;
  clientView?: ReferencePriceClientView;
}

export interface ReferenceCatalog<T> {
  vehicleReferences: Map<string, Map<string, T | NO_DATA>>; // Map<vehicleKey, Map<referenceNumber, object>>
  references: Map<string, T | NO_DATA>; // Map<referenceNumber, object>
}

export interface ReferenceTradingDataDetail {
  referenceNumber: string;
  commercialFamily: string;
  type: ReferenceType;
  quantity: number;
  supplierCode?: string;
  origin?: ORIGIN;
  referenceSource?: ReferenceSource;
}

// IAM ID for picture position for reference in vehicle
export const ADDITIONAL_INFORMATION_ID_P100 = 'P100';

export const NEW = 'NEW';
export const OLD = 'OLD';
export type VEHICLE_AGE = typeof NEW | typeof OLD;

//ONEPORTAL-1804
export const SORTING_MATRIX: Map<VEHICLE_AGE, Map<DealerType, ReferenceBrandType[]>> = new Map([
  [
    'NEW',
    new Map([
      ['R1', ['CENTRAL', 'STANDARD_EXCHANGE']],
      ['R2', ['CENTRAL', 'STANDARD_EXCHANGE']],
      ['R3', ['CENTRAL', 'STANDARD_EXCHANGE']],
      ['R3_MOTRIO', ['CENTRAL', 'STANDARD_EXCHANGE']],
    ]),
  ],
  [
    'OLD',
    new Map([
      ['R1', ['CENTRAL', 'STANDARD_EXCHANGE', 'VALUE_PLUS', 'MOTRIO']],
      ['R2', ['CENTRAL', 'STANDARD_EXCHANGE', 'VALUE_PLUS', 'MOTRIO']],
      ['R3', ['CENTRAL', 'MOTRIO', 'STANDARD_EXCHANGE', 'VALUE_PLUS']],
      ['R3_MOTRIO', ['MOTRIO', 'CENTRAL', 'VALUE_PLUS', 'STANDARD_EXCHANGE']],
    ]),
  ],
]);

export type ORIGIN = 'OE' | 'IAM';

export function getBrandLabel(brand: ReferenceBrandType | undefined, supplier: string | undefined): string {
  switch (brand) {
    case 'STANDARD_EXCHANGE':
      return 'STANDARD EXCHANGE';
    case 'VALUE_PLUS':
      return 'VALUE+';
    case 'MOTRIO':
      return 'MOTRIO';
    case 'IAM':
      return supplier ?? '';
    case 'IXELL':
      return 'IXELL';
    case 'IXTAR':
      return 'IXTAR';
    default:
      return '';
  }
}

export interface ReferenceStockStatus {
  searchStatus: SEARCH_STATUS;
  data: ReferenceStock;
}

export const REUSE_NODE_IDS = [
  'N-21375',
  'N-21376',
  'N-21377',
  'N-21378',
  'N-21379',
  'N-21380',
  'N-21381',
  'N-21960',
  'N-21977',
  'N-21978',
  'N-21985',
  'N-21992',
  'N-22003',
  'N-22004',
  'N-22026',
  'N-22047',
  'N-21926',
  'N-21927',
  'N-21928',
  'N-21935',
  'N-21936',
  'N-21880',
  'N-21602',
  'N-21604',
  'N-21606',
  'N-21615',
  'N-21617',
  'N-21620',
  'N-21621',
  'N-21626',
  'N-21627',
  'N-21629',
  'N-21630',
  'N-21634',
  'N-21635',
  'N-21636',
  'N-21638',
  'N-21639',
  'N-21640',
  'N-21648',
  'N-21649',
  'N-21650',
  'N-21651',
  'N-21652',
  'N-21654',
  'N-21655',
  'N-21668',
  'N-21673',
  'N-21889',
  'N-21890',
  'N-21891',
  'N-21912',
  'N-21577',
  'N-21578',
  'N-29312',
];

// Stocks sorted from slowest to fastest delivery
export const WAREHOUSE_STOCKS_ORDER: Warehouse[] = [
  'INVALID',
  'DELAYED',
  'OTHER',
  'MARKETPLACE',
  'EXADIS',
  'MANUFACTURER',
  'WW',
  'CENTRAL',
  'COUNTRY',
  'REGION',
  'LOCAL',
];

export const MAX_QUANTITY_WITH_STOCKS = 999;

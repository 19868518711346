import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TFunction } from 'i18next';
import {
  ROUTER_ASSISTANCE,
  ROUTER_ESTIMATE,
  ROUTER_ORDER_LIST,
  ROUTER_ORDERS,
  ROUTER_PROFILE,
  ROUTER_PROMOTION,
  ROUTER_TIRES,
  ROUTER_UNIVERSAL_PRODUCTS,
} from 'app/AppRouter';
import { RenaultGroupFooterIcon, TireMudIcon } from 'assets/icons';
import { ClarionLink } from 'components/Page/SubFooter/ClarionLink';
import { SContainer, SText } from 'components/Page/SubFooter/SubFooter.styled';
import { EstimateTabName, EstimateTabParam } from 'domains/estimate/Estimate.types';
import { getCurrentPromotionsData } from 'domains/promotion/Promotion.store';
import { getUserCountry } from 'domains/user';
import { CREATE_NEW } from 'pages/BackOfficePage/BackOfficePage.type';
import { theme } from 'styles';
import { Box, Flex, Icon, Link, MarginBox, Text } from 'UI';
import { getCondArrayItem } from 'utils';
import { Country } from 'utils/i18n/Country';

const ROUTER_EXTERNAL_E_GUIDE = 'https://www.e-guide.renault.com/portail/';
const ROUTER_EXTERNAL_USER_GUIDE = 'https://www.userguide-renaultgroup.com';
const ROUTER_EXTERNAL_SECONDE_VIE_AUTOMOBILE = 'https://sva2.dcs2.renault.com';
const ROUTER_EXTERNAL_REUSE = 'https://precis.sidexa.fr/idp.php';
const ROUTER_EXTERNAL_IDIR = 'https://www.all-parts.it/login.asp';

type LinkItem = { link: string; text: string; external?: boolean } | { component: ReactNode };
type LinkItemsColumn = { id: string; title: string; linkItems: LinkItem[] };

const getLinkItemsColumns = (
  t: TFunction,
  showCurrentPromotions: boolean,
  searchParams: URLSearchParams,
  country?: string,
): LinkItemsColumn[] => {
  const showTires = Country.canShowTiresByCountry(country);
  return [
    {
      id: '1',
      title: 'Get in touch with us',
      linkItems: [
        {
          link: ROUTER_ASSISTANCE,
          text: t('assistance', 'Assistance'),
        },
      ],
    },
    {
      id: '2',
      title: 'Solutions',
      linkItems: [
        {
          link: `${ROUTER_ORDER_LIST}/${CREATE_NEW}`,
          text: t('catalog.order_by_reference', 'Order by reference'),
        },
        {
          link: `${ROUTER_ESTIMATE}?${searchParams}`,
          text: t('footer.estimates', 'Estimates'),
        },
        {
          link: ROUTER_ORDERS,
          text: t('footer.my_orders', 'My orders'),
        },
      ],
    },
    {
      id: '3',
      title: 'Direct links',
      linkItems: [
        ...getCondArrayItem(
          showCurrentPromotions && {
            link: ROUTER_PROMOTION,
            text: t('promotions.current_promotions', 'Current promotions'),
          },
        ),
        {
          link: ROUTER_PROFILE,
          text: t('profile.my_profile', 'My profile'),
        },
        ...getCondArrayItem(
          showTires && {
            link: ROUTER_TIRES,
            icon: TireMudIcon,
            text: t('catalog.tires', 'Tires'),
          },
        ),
        {
          link: ROUTER_UNIVERSAL_PRODUCTS,
          text: t('catalog.universal_products', 'Universal products'),
        },
      ],
    },
    {
      id: '4',
      title: 'Other sites',
      linkItems: [
        {
          link: ROUTER_EXTERNAL_E_GUIDE,
          text: t('footer.e_guide', 'e-Guide'),
          external: true,
        },
        {
          link: ROUTER_EXTERNAL_USER_GUIDE,
          text: t('footer.user_guide', 'User guide'),
          external: true,
        },
        ...getCondArrayItem(
          country === Country.FR.key && {
            link: ROUTER_EXTERNAL_SECONDE_VIE_AUTOMOBILE,
            text: t('footer.seconde_vie_automobile', 'Seconde Vie Automobile'),
            external: true,
          },
        ),

        ...getCondArrayItem(
          country === Country.FR.key && {
            link: ROUTER_EXTERNAL_REUSE,
            text: t('footer.re_use', 'ReUse'),
            external: true,
          },
        ),

        ...getCondArrayItem(
          country === Country.IT.key && {
            link: ROUTER_EXTERNAL_IDIR,
            text: t('footer.idir', 'IDIR'),
            external: true,
          },
        ),
        {
          component: <ClarionLink text={t('footer.clarion-electronic_repair', 'Clarion - Electronic repair')} />,
        },
      ],
    },
  ];
};

export const SubFooter = () => {
  const { t } = useTranslation();
  const promotionsData = useSelector(getCurrentPromotionsData);
  const showCurrentPromotions = promotionsData?.length > 0;
  const country = useSelector(getUserCountry);
  const searchParams = new URLSearchParams(location.search);
  searchParams.set(EstimateTabParam, EstimateTabName);

  return (
    <SContainer>
      <Flex direction={'row'} justify={'space-around'} gap={32}>
        <Flex />
        <Flex>
          <Icon
            IconComponent={RenaultGroupFooterIcon}
            width={145}
            height={65}
            mt={5}
            mr={60}
            color={theme.color.white}
            display={'block'}
          />
        </Flex>
        {getLinkItemsColumns(t, showCurrentPromotions, searchParams, country).map((linkItemsColumn) => {
          return (
            <Flex direction={'column'} key={linkItemsColumn.id}>
              <MarginBox mb={18}>
                <Text type={'lead_text_white'} noWrap>
                  {linkItemsColumn.title}
                </Text>
              </MarginBox>
              {linkItemsColumn.linkItems.map((linkItem, i) =>
                'link' in linkItem ? (
                  <Box key={linkItem.link}>
                    <Link to={linkItem.link} external={linkItem.external} newTab={linkItem.external}>
                      <SText type={'dark_14_white_65'} noWrap>
                        {linkItem.text}
                      </SText>
                    </Link>
                  </Box>
                ) : (
                  <Box key={`link_${i}`}>{linkItem.component}</Box>
                ),
              )}
            </Flex>
          );
        })}
        <Flex />
      </Flex>
    </SContainer>
  );
};

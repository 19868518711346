import { GET_CLARION_LINK_RESPONSE } from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { GET_CLARION_LINK } from '@1po/1po-bff-fe-spec/generated/common/WebsocketConstants';
import { RequestResponseMappingManager } from 'domains/webSockets/WebSocket.requestsResponseMapping';
import { WebSocketAction } from 'utils';
import { wsSendQueryAction } from 'utils/domainStore/api';

RequestResponseMappingManager.addMapping(GET_CLARION_LINK, GET_CLARION_LINK_RESPONSE);

export function sendGetClarionLink(): WebSocketAction {
  return wsSendQueryAction(GET_CLARION_LINK, null);
}

import React, { PropsWithChildren } from 'react';
import { Spin as AntSpin } from 'antd-v5';
import { SpinSize } from 'antd-v5/lib/spin';
import styled from 'styled-components';
import { Disclosure, InfoDialog } from 'components/Dialog';
import Popover from 'components/Popover';
import { Box, CenterFlex } from 'UI';

export interface SpinProps {
  size?: SpinSize;
  tip?: string;
}

export interface SpinDialogProps extends SpinProps {
  disclosure: Disclosure;
  handleConfirm: () => void;
  title: string;
}

export const SSpin = styled(AntSpin)`
  user-select: none;
`;

export const Spin = ({ size, tip }: SpinProps) => <SSpin size={size} tip={tip} />;

export const CenteredSpin = (props: SpinProps) => (
  <CenterFlex>
    <Spin {...props} />
  </CenterFlex>
);

export const TooltipSpin = (props: PropsWithChildren<SpinProps>) => (
  <Popover
    placement={'bottom'}
    trigger={'hover'}
    content={
      <Box height={41} width={35}>
        <CenterFlex>
          <Spin {...props} />
        </CenterFlex>
      </Box>
    }
  >
    {props.children}
  </Popover>
);

export const SpinDialog = (props: PropsWithChildren<SpinDialogProps>) => (
  <InfoDialog
    {...props}
    buttonsText={undefined}
    width={310}
    content={
      <Box height={100} width={300}>
        <CenterFlex>
          <Spin {...props} />
        </CenterFlex>
      </Box>
    }
  ></InfoDialog>
);

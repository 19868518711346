import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { TFunction } from 'i18next';
import { ROUTER_ESTIMATE } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { trackAppEvent } from 'app/AppTracker';
import { SaveIcon } from 'assets/icons';
import { Dialog, useDisclosure } from 'components/Dialog';
import { fetchVehicleFunction } from 'domains/catalog/Catalog.requests';
import { getLastSearchedVehicleKey, getLastVehicleDetail } from 'domains/catalog/Catalog.store';
import { useFetchAvailableDMSServices } from 'domains/dms/DMS.requests';
import { useFetchEstimate, useFetchEstimateSettingsSubscription } from 'domains/estimate/Estimate.requests';
import {
  createEstimateFromDMSRequest,
  createNewEstimate,
  getCurrentEstimateId,
  getEstimateById,
  getEstimateSequenceNumber,
  getRowTablesCount,
  setSelectedTab,
} from 'domains/estimate/Estimate.store';
import { ESTIMATE_TAB, EstimateIdParam, EstimateTabName, EstimateTabParam } from 'domains/estimate/Estimate.types';
import { getAvailableDMSService } from 'domains/user';
import EstimateModifyTab from 'pages/EstimatePage/EstimateModifyTab';
import HistoryTab from 'pages/EstimatePage/HistoryTab';
import { SettingsTab } from 'pages/EstimatePage/SettingTab/SettingsTab';
import { Box, CenterFlex, Container, Flex, Tab, TabItems, Tabs, Text, YellowButton } from 'UI';
import { getData, LOADING } from 'utils';
import {
  TRACKING_EVENT_CREATE_NEW_ESTIMATE,
  TRACKING_EVENT_CREATE_NEW_ESTIMATE_CONFIRM,
  TRACKING_EVENT_ESTIMATE_HISTORY,
} from 'utils/eventTracker/EventTracker.types';
import { useResetScroll } from 'utils/hooks/useResetScroll';
import CreateEstimateButton from './CreateEstimateButton';
import { SPopover } from './EstimatePage.styled';

const getTabItems = (
  t: TFunction,
  currentEstimateId: string,
  isDMSAvailable: boolean | typeof LOADING,
  changeTab: (tab: ESTIMATE_TAB) => void,
): TabItems[] => {
  return [
    {
      key: 'estimate',
      label: t('estimate', 'Estimate'),
      component: (
        <EstimateModifyTab estimateId={currentEstimateId} key={currentEstimateId} isDMSAvailable={isDMSAvailable} />
      ),
    },
    {
      key: 'history',
      label: t('estimate.history', 'History'),
      additionalAction: () => trackAppEvent(TRACKING_EVENT_ESTIMATE_HISTORY),
      component: <HistoryTab switchToEstimateTab={() => changeTab(EstimateTabName)} />,
    },
    {
      key: 'settings',
      label: t('estimate.settings', 'Settings'),
      component: <SettingsTab />,
    },
  ];
};

export default function EstimatePage({
  estimateId,
  tabKey,
  setTabKey,
}: {
  estimateId: string;
  tabKey: ESTIMATE_TAB;
  setTabKey: React.Dispatch<React.SetStateAction<ESTIMATE_TAB>>;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const vehicle = useSelector(getLastVehicleDetail) as VehicleDetail;
  const disclosure = useDisclosure();
  const [isCreateEstimatePopoverVisible, setIsCreateEstimatePopoverVisible] = useState(false);
  const [isDMSImportActive, setIsDMSImportActive] = useState(false);

  const { onOpen } = disclosure;
  const history = useHistory();
  const sequenceNumber = useSelector((state: RootState) => getEstimateSequenceNumber(state, estimateId));
  const numberOfItems = useSelector((state: RootState) => getRowTablesCount(state, estimateId));
  const isDMSAvailable = useSelector((state: RootState) => getAvailableDMSService(state, 'ESTIMATE'));
  const currentEstimateId = useSelector(getCurrentEstimateId);
  const params = useMemo(
    () => new URLSearchParams(location.search),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentEstimateId],
  );
  const estimate = useSelector((state: RootState) => getEstimateById(state, estimateId));
  const estimateData = getData(estimate);
  const catalogVehicleKey = useSelector(getLastSearchedVehicleKey);

  useResetScroll([estimateId]);
  useFetchEstimate(estimateId);
  useFetchAvailableDMSServices();
  useFetchEstimateSettingsSubscription();

  useEffect(() => {
    const estimateVehicle = estimateData?.vehicle;
    if (estimateVehicle && estimateVehicle.vehicleKey !== catalogVehicleKey) {
      fetchVehicleFunction(
        dispatch,
        estimateVehicle.vehicleKey,
        false,
        estimateVehicle.country,
        estimateVehicle.catalogSource,
        undefined,
        estimateVehicle.iamVehicle?.versionCode,
      );
    }
    // eslint-disable-next-line
  }, [estimateData]);

  const handleConfirm = () => {
    trackAppEvent(TRACKING_EVENT_CREATE_NEW_ESTIMATE_CONFIRM);
    dispatch(createNewEstimate({ vehicle }));
    setTabKey(EstimateTabName);
    params.set(EstimateTabParam, EstimateTabName);
    params.delete(EstimateIdParam);
    history.push(`${ROUTER_ESTIMATE}?${params}`);
  };

  const changeTab = (tab: ESTIMATE_TAB) => {
    setTabKey(tab);
    dispatch(setSelectedTab(tab));
    tabItems.find((item) => tab === item.key)?.additionalAction?.();
    params.set(EstimateTabParam, tab);
    params.delete(EstimateIdParam);
    history.replace(`${location.pathname}?${params}`);
  };

  const tabItems = getTabItems(t, currentEstimateId, isDMSAvailable, changeTab);

  function createEstimate() {
    trackAppEvent(TRACKING_EVENT_CREATE_NEW_ESTIMATE);
    onOpen();
  }

  function importDMSOrder(orderNumber: string) {
    dispatch(createEstimateFromDMSRequest({ orderNumber }));
  }

  return (
    <Container>
      <CenterFlex>
        <Dialog
          disclosure={disclosure}
          title={t('common.dialog.actionRequired', 'Action required')}
          description={
            t(
              'estimate.create_new.description',
              'You’re about to create a new estimate, your current one will be saved in your estimate  history.',
            ) as string
          }
          icon={SaveIcon}
          status={'info'}
          handleConfirm={handleConfirm}
        />
      </CenterFlex>
      <Flex>
        <Flex direction={'row-reverse'}>
          <Box>
            <YellowButton
              onClick={() => {
                if (!isDMSAvailable) {
                  createEstimate();
                  return;
                }
                setIsCreateEstimatePopoverVisible(true);
              }}
              disabled={numberOfItems === 0}
            >
              {t('estimate.create_new', 'Create new estimate')}
            </YellowButton>
            <SPopover
              id={'create-new-estimate-popover'}
              getPopupContainer={(trigger) => trigger.parentElement ?? trigger}
              content={
                <CreateEstimateButton
                  createEstimate={createEstimate}
                  isDMSAvailable={isDMSAvailable}
                  importDMSOrder={importDMSOrder}
                  setIsCreateEstimatePopoverVisible={setIsCreateEstimatePopoverVisible}
                  isDMSImportActive={isDMSImportActive}
                  setIsDMSImportActive={setIsDMSImportActive}
                />
              }
              trigger={'click'}
              placement={'bottomRight'}
              visible={isCreateEstimatePopoverVisible}
              onVisibleChange={(visible) => {
                setIsCreateEstimatePopoverVisible(visible);
                if (visible === false) {
                  setIsDMSImportActive(false);
                }
              }}
            />
          </Box>
        </Flex>
      </Flex>
      {tabKey === EstimateTabName ? (
        <Text type={'h0'}>
          {t('estimate', 'Estimate')}
          {sequenceNumber && ` ${sequenceNumber}`}
        </Text>
      ) : (
        <Box height={110} />
      )}
      <Tabs activeKey={tabKey} onChange={changeTab} tabBarGutter={15}>
        {tabItems?.map((item) => (
          <Tab tab={item.label} key={item.key}>
            {item.component}
          </Tab>
        ))}
      </Tabs>
    </Container>
  );
}

/* eslint-disable max-len */
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReferenceBrandType } from '@1po/1po-bff-fe-spec/generated/catalog/references/common/model/ReferenceBrandType';
import { Document } from '@1po/1po-bff-fe-spec/generated/catalog/references/iam/model/Document';
import {
  Origin,
  ReferenceSource,
} from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/request/GetReferenceTradingDataRequest';
import { ROUTER_ESTIMATE } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { trackAppEvent } from 'app/AppTracker';
import { AddToEstimateButtonAndDialog } from 'components/AddToEstimate/AddToEstimateButtonAndDialog';
import { CopyToClipboardButton } from 'components/CopyToClipboardButton';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import { DiscountCorner } from 'components/Discount';
import { DocumentationAlertButton } from 'components/DocumentationAlertButton';
import { DocumentScrollModal } from 'components/DocumentScrollModal/DocumentScrollModal';
import { positionHasPicture } from 'components/Filter/Filter.types';
import { ReferencePriceSection } from 'components/ReferencePriceSection';
import { ReferenceUnavailableForOrder } from 'components/ReferenceUnavailableBox/ReferenceUnavailable';
import StockDisplay from 'components/StockInfo';
import { getLastVehicleDetail } from 'domains/catalog/Catalog.store';
import {
  IAM,
  IAMLaborTimesWrapper,
  OEM_BRAND_KEY,
  PARAM_LABOR_TIME,
  PreferredTabParam,
  STANDARD,
} from 'domains/catalog/Catalog.types';
import { convertReferenceToEstimateReference } from 'domains/estimate/Estimate.mapper';
import { addCatalogReference, setSelectedTab } from 'domains/estimate/Estimate.store';
import { EstimateTabName } from 'domains/estimate/Estimate.types';
import { getIAMReference, IAMReferenceLocal, ReferencePriceType } from 'domains/references';
import { isRefMKTP } from 'domains/references/References.utils';
import { GarageView, getDealerType, getIamCatalogBrandsView, SparePartsViewType } from 'domains/user';
import { DefaultCarImage } from 'pages/CatalogPage/common';
import ElectronicPartsRepairLink from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/ElectronicPartsRepairLink';
import {
  ASSETS,
  BlackButton,
  Box,
  CenteredSpin,
  Descriptions,
  Flex,
  Image,
  INITIAL_ITEM_COUNT,
  Item,
  LinkRoundButton,
  MarginBox,
  Spin,
  Text,
  URL,
  WithTooltip,
} from 'UI';
import { NotificationLink, notifyTop } from 'UI/Notification/notification';
import { getData, hasPrice, NO_DATA, textFormatter } from 'utils';
import { TRACKING_EVENT_ADD_TO_ESTIMATE, TRACKING_EVENT_GO_TO_ESTIMATE } from 'utils/eventTracker/EventTracker.types';
import { SCard, SReferenceImageCard } from './IAMReferenceCard.styled';
import ReferenceImage from './ReferenceImage';

export const STANDARD_EXCHANGE_REFERENCE_PATTERN = '81008';

export const SupersessionTooltip = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();

  return (
    <WithTooltip title={t('catalog.details.supersession_chain', 'Supersession chain')}>
      <div>{children}</div>
    </WithTooltip>
  );
};

interface ReferenceCardProps {
  name?: string;
  supplier?: string;
  rawLabels: string[];
  secondaryName?: string;
  referenceNumber: string;
  brand?: ReferenceBrandType;
  price?: ReferencePriceType;
  sparePartsView: SparePartsViewType;
  vehicleKey: string | undefined;
  brandImage?: string;
  referenceImage?: string;
  laborTimesWrapper?: NO_DATA | IAMLaborTimesWrapper;
  position: string | undefined;
  documents: Document[];
  isIamSearchResult?: boolean;
  selected?: boolean;
  isMaintenance?: boolean;
  onSelect?: (referenceNumber: string) => void;
  replacingReferences: string[];
  substitutedReferences: string[];
  origin?: Origin;
  supplierCode?: string;
  referenceSource?: ReferenceSource;
  additionalInformationItems: Item[];
  handleAddToCart: () => void;
}

const IAMReferenceCard = React.memo(function IAMReferenceCard({
  referenceNumber,
  brand,
  name,
  supplier,
  rawLabels,
  secondaryName,
  sparePartsView,
  price,
  vehicleKey,
  brandImage,
  referenceImage,
  laborTimesWrapper,
  position,
  documents,
  isIamSearchResult,
  isMaintenance,
  selected,
  onSelect,
  replacingReferences,
  substitutedReferences,
  origin,
  supplierCode,
  referenceSource,
  additionalInformationItems,
  handleAddToCart,
}: ReferenceCardProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const vehicleDetail = useSelector(getLastVehicleDetail);
  const vehicleImage = vehicleDetail?.imageUrl;
  const detailWithVehicle = useSelector((state: RootState) =>
    getIAMReference(state, { vehicleKey: vehicleDetail?.vehicleKey, referenceNumber }),
  );
  const detailWithoutVehicle = useSelector((state: RootState) =>
    getIAMReference(state, { vehicleKey: undefined, referenceNumber }),
  );
  const detail = detailWithVehicle ?? detailWithoutVehicle;
  const iamCatalogBrandsView = useSelector(getIamCatalogBrandsView);
  const [documentsModalVisible, setDocumentsModalVisible] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const isR1 = useSelector(getDealerType) === 'R1';
  const availableForOrder = vehicleDetail?.vehicleBrand === 'ALPINE' ? isR1 : true;
  const isOEM = iamCatalogBrandsView === OEM_BRAND_KEY && !isIamSearchResult;
  const additionalElements = documents.length - 1;

  const displayShowMoreButton = additionalInformationItems.length > INITIAL_ITEM_COUNT;
  const displaySubstitutionButton =
    displayShowMoreButton && (replacingReferences.length > 0 || substitutedReferences.length > 0);
  const initialItems = additionalInformationItems.slice(0, INITIAL_ITEM_COUNT);
  const [currentData, setCurrentData] = useState(initialItems);
  const displayName = [name, supplier, ...rawLabels].filter(Boolean).join(' / ');
  const translatedName = referenceNumber.startsWith(STANDARD_EXCHANGE_REFERENCE_PATTERN)
    ? `${name} [${t('catalog.iam.standard_exchange', 'STANDARD EXCHANGE')}]`
    : name;
  const translatedDisplayName = [translatedName, supplier, ...rawLabels].filter(Boolean).join(' / ');

  const handleShowMore = () => {
    setCurrentData(showMore ? initialItems : [...additionalInformationItems]);
    setShowMore((prevState) => !prevState);
  };

  const redirectToLaborTime = () => {
    const params = new URLSearchParams(location.search);
    params.set(PreferredTabParam, PARAM_LABOR_TIME);
    history.push(`${location.pathname}?${params}`);
  };

  const renderAddLaborTime = () => {
    return (
      <DataContainer
        data={laborTimesWrapper}
        Loading={() => <Spin />}
        NotFound={() => (
          <Text type={'light_12'} displayStyle={'disabled'} cursor={'pointer'} hoverUnderLine>
            {t('common.action.add_labor_time', 'Add Labor time')}
          </Text>
        )}
        ErrorState={() => (
          <ErrorWithLabel
            label={t('common.labor_times.backend_error.short', 'Labor times temporarily unavailable.')}
            narrow
          />
        )}
      >
        <Text
          hoverUnderLine
          onClick={() => redirectToLaborTime()}
          type={'light_12'}
          displayStyle={'link'}
          cursor={'pointer'}
        >
          {t('common.action.add_labor_time', 'Add Labor time')}
        </Text>
        <Box width={10} />
      </DataContainer>
    );
  };

  const handleAddToEstimateClick = () => {
    if (price?.garageView?.vatExcludedPrice) {
      notifyTop(
        'success',
        <Trans i18nKey={'catalog.reference_card.added_to_estimate.description'}>
          {'Reference has been added to your estimate.'}
        </Trans>,
        undefined,
        <NotificationLink
          onClick={() => {
            trackAppEvent(TRACKING_EVENT_GO_TO_ESTIMATE);
            dispatch(setSelectedTab(EstimateTabName));
            history.push(`${ROUTER_ESTIMATE}`);
          }}
        >
          <Trans i18nKey={'catalog.reference_card.added_to_estimate.go_to_estimate'}>{'Go to estimate'}</Trans>
        </NotificationLink>,
      );
      trackAppEvent(TRACKING_EVENT_ADD_TO_ESTIMATE);
      dispatch(
        addCatalogReference({
          reference: convertReferenceToEstimateReference(
            referenceNumber,
            displayName ?? '',
            price,
            true,
            'IAM',
            origin,
            supplierCode,
            referenceSource,
          ),
        }),
      );
    }
  };

  const renderDefaultCarImage = () => {
    return (
      <>
        <Flex>
          <DefaultCarImage />
          {displaySubstitutionButton && (
            <Flex align={'flex-end'}>
              <MarginBox mr={-15} mt={55} />
              <SupersessionTooltip>
                <LinkRoundButton onClick={handleShowMore} />
              </SupersessionTooltip>
            </Flex>
          )}
        </Flex>
      </>
    );
  };

  const renderPictureSection = () => {
    if (isOEM) {
      return (
        <MarginBox mt={5}>
          <Box width={displaySubstitutionButton ? 135 : 120} height={displaySubstitutionButton ? 135 : 120}>
            <DataContainer
              data={vehicleImage}
              Loading={() => (
                <Box width={70}>
                  <CenteredSpin />
                </Box>
              )}
              NotFound={() => renderDefaultCarImage()}
              Skeleton={() => renderDefaultCarImage()}
              ErrorState={() => renderDefaultCarImage()}
            >
              <Flex>
                <Image src={vehicleImage} type={URL} alt={'carPic'} width={120} height={120} />
                {displaySubstitutionButton && (
                  <Flex justify={'flex-end'} align={'flex-end'}>
                    <MarginBox mt={-15} mr={-15} />
                    <SupersessionTooltip>
                      <LinkRoundButton onClick={handleShowMore} />
                    </SupersessionTooltip>
                  </Flex>
                )}
              </Flex>
            </DataContainer>
          </Box>
        </MarginBox>
      );
    }
    return (
      <MarginBox ml={5} mt={5} mr={15}>
        <Box width={displaySubstitutionButton ? 150 : 135} height={displaySubstitutionButton ? 135 : 120}>
          <Flex>
            <DocumentScrollModal
              documents={documents}
              visible={documentsModalVisible}
              onVisibleChange={(visible) => setDocumentsModalVisible(visible)}
            >
              <SReferenceImageCard>
                <ReferenceImage brandImage={brandImage} referenceImage={referenceImage} />
              </SReferenceImageCard>
            </DocumentScrollModal>
            {displaySubstitutionButton && (
              <Flex justify={'flex-end'} align={'flex-end'}>
                <MarginBox mt={-15} mr={-15} />
                <SupersessionTooltip>
                  <LinkRoundButton onClick={handleShowMore} />
                </SupersessionTooltip>
              </Flex>
            )}
          </Flex>
          {documents.length > 1 && (
            <Flex justify={'flex-start'}>
              <Text
                type={'text'}
                displayStyle={'link'}
                cursor={'pointer'}
                hoverUnderLine
                onClick={(e: void) => {
                  setDocumentsModalVisible(true);
                  (e as any).stopPropagation();
                }}
              >
                {`+${additionalElements} ${t('common.elements', 'elements', {
                  count: additionalElements,
                })}`}
              </Text>
            </Flex>
          )}
        </Box>
      </MarginBox>
    );
  };

  const renderPriceSection = () => {
    if (isOEM) {
      return (
        <MarginBox mr={30}>
          <Flex direction={'column'} align={'center'} justify={'center'} minHeight={110}>
            <MarginBox mt={15}>
              <Text type={'h5_bold'}>
                {t('common.price.vat_exclude_price', '{{vat_exclude_price}} VAT. Excl', {
                  vat_exclude_price: textFormatter.formatCurrency(
                    Number(price?.garageView?.vatExcludedPrice),
                    price?.currency ?? '',
                  ),
                })}
              </Text>
            </MarginBox>
            <Flex direction={'column-reverse'}>
              <BlackButton onClick={handleAddToEstimateClick}>
                {t('catalog.action.add_to_estimate', 'Add to estimate')}
              </BlackButton>
            </Flex>
          </Flex>
        </MarginBox>
      );
    }

    const getIsMktp = (detailVehicle: NO_DATA | IAMReferenceLocal, detailNoVehicle: NO_DATA | IAMReferenceLocal) => {
      const detail = detailVehicle ? detailVehicle : detailNoVehicle;
      return isRefMKTP(getData(detail));
    };

    return (
      <ReferencePriceSection
        align={'left'}
        referenceNumber={referenceNumber}
        vehicleKey={vehicleKey}
        sparePartsView={sparePartsView}
        handleAddToCartClick={handleAddToCart}
        isApplicableToCurrentVehicle={!isIamSearchResult}
        catalogSource={IAM}
        referenceType={STANDARD}
        availableForOrder={availableForOrder}
        origin={origin}
        referenceSource={referenceSource}
        supplierCode={supplierCode}
        isMKTP={getIsMktp(detailWithVehicle, detailWithoutVehicle)}
      />
    );
  };

  const renderEstimateSection = () =>
    hasPrice(sparePartsView, price) && !isOEM && !isMaintenance ? (
      <AddToEstimateButtonAndDialog
        handleAddToEstimateClick={handleAddToEstimateClick}
        displayed={availableForOrder}
        isVehicleCatalog
      />
    ) : null;

  function handleClick(referenceNumber: string) {
    isMaintenance && onSelect && onSelect(referenceNumber);
  }

  function handlePreventPropagate(e: void) {
    (e as any).stopPropagation();
  }

  return (
    <>
      <SCard selected={selected} isSquared={!availableForOrder} onClick={() => handleClick(referenceNumber)}>
        {sparePartsView === GarageView && <DiscountCorner reference={referenceNumber} type={'catalog'} />}
        <Flex direction={'column'}>
          <MarginBox my={15} ml={5} mr={15}>
            <Flex wrap={'wrap'}>
              <MarginBox mr={5} />
              {renderPictureSection()}
              <MarginBox mr={15} />
              <Flex direction={'column'} align={'flex-start'}>
                <Flex size={0} align={'center'}>
                  <Text type={'h2'} disableGutter>
                    {t('catalog.reference_card.reference_number', 'Ref:')} {referenceNumber.toUpperCase()}
                  </Text>
                  <MarginBox ml={10} />
                  <CopyToClipboardButton
                    textToCopy={referenceNumber}
                    message={t(
                      'catalog.reference_card.reference_number.copied_to_clipboard',
                      'Reference number {{referenceNumber}} copied to clipboard',
                      { referenceNumber },
                    )}
                  />
                  {(brand === 'MOTRIO' || getData(detail)?.supplier === 'MOTRIO') && (
                    <>
                      <MarginBox mr={1} />
                      <DocumentationAlertButton
                        reference={getData(detail)}
                        vehicleDetail={vehicleDetail}
                        vehicleRegistrationNumber={vehicleDetail?.vrn ?? vehicleDetail?.vehicleKey}
                      />
                    </>
                  )}
                  {positionHasPicture(position, t) && (
                    <Flex minWidth={80}>
                      <MarginBox ml={15} />
                      <Image
                        src={`referencePositionInVehicle/${position}.png`}
                        type={ASSETS}
                        alt={'ref-vehicle-position'}
                        height={33}
                      />
                      <MarginBox ml={15} />
                    </Flex>
                  )}
                </Flex>
                <Flex basis={4}>
                  <Flex size={translatedDisplayName && secondaryName ? 3 : 4}>
                    <Text type={'text_dim_bold'}>{translatedDisplayName}</Text>
                  </Flex>
                  {translatedDisplayName && secondaryName && (
                    <Flex size={1}>
                      <Text type={'text_dim_bold'}>&nbsp;</Text>
                      <Text type={'text_dim'} noWrap>
                        {translatedDisplayName && secondaryName ? ` / ${secondaryName}` : secondaryName}
                      </Text>
                    </Flex>
                  )}
                </Flex>
                {!isOEM && (
                  <Flex direction={'column'} justify={'flex-start'}>
                    <StockDisplay
                      vehicleKey={vehicleKey}
                      referenceNumber={referenceNumber}
                      isApplicableToCurrentVehicle
                    />
                  </Flex>
                )}
                <Flex direction={'column'} justify={'flex-start'}>
                  {laborTimesWrapper && <>{renderAddLaborTime()}</>}
                  <ElectronicPartsRepairLink referenceNumber={referenceNumber} type={'light_12'} />
                </Flex>
              </Flex>
              <MarginBox mr={15} />
              <Flex direction={'row'} justify={'flex-end'} onClick={handlePreventPropagate} size={1}>
                {renderPriceSection()}
                {renderEstimateSection()}
              </Flex>
            </Flex>
          </MarginBox>
          {additionalInformationItems.length > 0 && (
            <Descriptions
              title={t('catalog.details.additional_information', 'Additional information')}
              items={currentData}
              displayShowMoreButton={displayShowMoreButton}
              showMore={showMore}
              handleShowMore={handleShowMore}
            />
          )}
        </Flex>
      </SCard>
      <ReferenceUnavailableForOrder enabled={!availableForOrder} />
    </>
  );
});

export default IAMReferenceCard;

import { ClarionLinkResponse } from '@1po/1po-bff-fe-spec/generated/clarion/ClarionLinkResponse';
import { APP_NAMESPACE } from 'app/App.constants';
import { SearchData } from 'utils';

export const CLARION_NAMESPACE = `${APP_NAMESPACE}/CLARION`;

export const GET_CLARION_LINK = `${CLARION_NAMESPACE}/GET_CLARION_LINK_REQUEST`;

export interface ClarionState {
  clarionLink: SearchData<ClarionLinkResponse>;
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { CheckCircleIcon } from 'assets/icons';
import { calculatePriceAfterDiscount } from 'components/Discount';
import StockDisplay from 'components/StockInfo';
import { ReferenceLocal, VehicleLocal } from 'domains/basket/Basket.types';
import { getDiscount, getPrice } from 'domains/references';
import { getCurrency } from 'domains/user';
import {
  ReferenceCardWrapper,
  ReferencePicBackground,
} from 'pages/CartPage/ValidationStep/VerificationCard/VerificationCard.styled';
import { ReferenceImage } from 'pages/CartPage/ValidationStep/VerificationCard/VerificationImage';
import { theme } from 'styles';
import { Box, Flex, Icon, Text } from 'UI';
import { getData, textFormatter } from 'utils';

export const ReferenceCard = ({
  reference,
  vehicle,
  index,
}: {
  reference: ReferenceLocal;
  vehicle: VehicleLocal | undefined;
  index?: number;
}) => {
  const { t } = useTranslation();
  const currency = useSelector(getCurrency);
  const discount = useSelector((state: RootState) => getDiscount(state, reference.referenceNumber));
  const price = useSelector((state: RootState) => getPrice(state, reference.referenceNumber));
  const priceData = getData(price);

  const discountPrice = calculatePriceAfterDiscount(discount, priceData, false, reference.isUrgent);
  const vehicleKey = vehicle?.vehicleDetail.vehicleKey;

  return (
    <ReferenceCardWrapper index={index}>
      <Flex direction={'row'} align={'center'} gap={15}>
        <ReferencePicBackground>
          <ReferenceImage reference={reference} vehicleDetail={vehicle?.vehicleDetail} />
        </ReferencePicBackground>
        <Flex direction={'column'}>
          <Text type={'h4_paragraph'} dataCy={'checkout-reference'}>
            {t('order.verification.reference.referenceNumber.short', 'Ref. {{referenceNumber}}', {
              referenceNumber: reference.referenceNumber,
            })}
          </Text>
          <Text type={'text_semibold_black_85'} dataCy={'checkout-designation'}>
            {reference.name}
          </Text>
          <StockDisplay
            vehicleKey={vehicleKey}
            referenceNumber={reference.referenceNumber}
            isApplicableToCurrentVehicle
          />
        </Flex>
        <Flex direction={'column'} align={'center'} maxWidth={150}>
          <Text type={'text_bold'} dataCy={'checkout-quantity'}>
            {t('order.verification.reference.units', '{{count}} Units', { count: reference.quantity })}
          </Text>
        </Flex>
        <Flex direction={'column'} align={'center'} maxWidth={150}>
          <Text type={'text_bold'} dataCy={'checkout-price'}>
            {textFormatter.formatCurrency(Number(discountPrice) * reference.quantity, currency)}
          </Text>
          {reference.isUrgent && (
            <Flex align={'center'}>
              <Flex align={'center'} justify={'center'} maxWidth={9} maxHeight={9} background={'#0050b3'}>
                <Icon IconComponent={CheckCircleIcon} size={16} color={theme.color.brand} />
              </Flex>
              <Box width={5} />
              <Text type={'mobile_h3'} displayStyle={'link'}>
                {t('order.verification.reference.urgent', 'Urgent')}
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
    </ReferenceCardWrapper>
  );
};

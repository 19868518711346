import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { VehicleIAMPartCategoryTreeItem } from '@1po/1po-bff-fe-spec/generated/catalog/category/model/VehicleIAMPartCategoryTreeItem';
import { VehiclePartCategoryTreeItem } from '@1po/1po-bff-fe-spec/generated/catalog/category/model/VehiclePartCategoryTreeItem';
import { ROUTER_CATALOG_VEHICLE } from 'app/AppRouter';
import { getExplodedIAMTreeItem, getExplodedTreeItem } from 'domains/catalog/Catalog.store';
import { getCatalogSourceUrl, isDH, useCatalogSourceLocation } from 'domains/catalog/Catalog.utils';
import { Breadcrumb } from 'UI';

const CatalogBreadcrumb = ({
  tree,
}: {
  tree: VehiclePartCategoryTreeItem[] | VehicleIAMPartCategoryTreeItem[] | undefined;
}) => {
  const { t } = useTranslation();
  const { query, carPartGroupId, categoryId, subcategoryId } = useParams<{
    query: string;
    carPartGroupId: string;
    categoryId: string;
    subcategoryId: string;
  }>();
  const catalogSource = useCatalogSourceLocation();
  const catalogSourceUrl = getCatalogSourceUrl(catalogSource);

  const getItem = (arr: string[]) => {
    if (isDH(catalogSource)) {
      return getExplodedTreeItem(tree as VehiclePartCategoryTreeItem[], arr);
    } else {
      return getExplodedIAMTreeItem(tree, arr);
    }
  };

  const carPartGroup = getItem([carPartGroupId]);
  const category = getItem([carPartGroupId, categoryId]);
  const subcategory = getItem([carPartGroupId, categoryId, subcategoryId]);

  if (carPartGroupId && !carPartGroup) return null;

  const getBreadcrumbItems = () => {
    const breadcrumbItems = [
      {
        link: '/',
        label: t('common.home', 'Home'),
      },
      {
        link: `${catalogSourceUrl}/${query}${ROUTER_CATALOG_VEHICLE}`,
        label: t('common.my_vehicle', 'My vehicle'),
      },
    ];

    carPartGroupId &&
      carPartGroup &&
      breadcrumbItems.push({
        link: `${catalogSourceUrl}/${query}${ROUTER_CATALOG_VEHICLE}/${carPartGroupId}`,
        label: carPartGroup?.label ?? '',
      });
    categoryId &&
      category &&
      breadcrumbItems.push({
        link: `${catalogSourceUrl}/${query}${ROUTER_CATALOG_VEHICLE}/${carPartGroupId}/${categoryId}`,
        label: category?.label ?? '',
      });
    subcategoryId &&
      subcategory &&
      breadcrumbItems.push({
        link: `${catalogSourceUrl}/${query}${ROUTER_CATALOG_VEHICLE}/${carPartGroupId}/${categoryId}/${subcategoryId}`,
        label: subcategory?.label ?? '',
      });
    return breadcrumbItems;
  };

  return <Breadcrumb items={getBreadcrumbItems()} />;
};

export default CatalogBreadcrumb;

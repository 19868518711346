import { ClarionLinkResponse } from '@1po/1po-bff-fe-spec/generated/clarion/ClarionLinkResponse';
import { WsResponse } from '@1po/1po-bff-fe-spec/generated/common/WsResponse';
import { put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import { LOADING, mapQueryWsResponseToSearchData, sagaGuard } from 'utils';
import { sendGetClarionLink } from './Clarion.api';
import * as actions from './Clarion.store';
import { setClarionLink } from './Clarion.store';

export function* fetchClarionLinkRequest(): SagaIterator {
  yield put(setClarionLink({ searchStatus: LOADING }));
  yield put(sendGetClarionLink());
}

export function* fetchClarionLinkResponse(action: WsResponse<ClarionLinkResponse>): SagaIterator {
  yield put(setClarionLink(mapQueryWsResponseToSearchData(action)));
}

export function* ClarionSagas(): SagaIterator {
  yield takeEvery(actions.fetchClarionLinkRequest.type, sagaGuard(fetchClarionLinkRequest));
  yield takeEvery(actions.fetchClarionLinkResponse.type, sagaGuard(fetchClarionLinkResponse));
}

import React, { ReactNode, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ROUTER_HOME, ROUTER_TIRES, ROUTER_UNIVERSAL_PRODUCTS } from 'app/AppRouter';
import { getElectronicPartsRepairLink } from 'domains/appContext/AppContext.store';
import { useAutomaticFirstHelp } from 'domains/firstHelp/FirstHelp.requests';
import { getPromotionBanners } from 'domains/promotion/Promotion.store';
import { PromotionBannerCarousel } from 'domains/promotion/Promotion.types';
import { getUserCountry, getVehicleSearchHistory } from 'domains/user';
import { HomepageLg } from 'pages/HomePage/HomepageLg';
import { HomepageSm } from 'pages/HomePage/HomepageSm';
import { Box, Flex, FlexJustifyType, Link, PUBLIC, Text } from 'UI';
import { notifyNotImplemented } from 'UI/Notification/notification';
import { getData, hasData, useSmall } from 'utils';
import { useBreakpointSelector, useBreakpointSelectorFull } from 'utils/hooks/useBreakpoint';
import { useResetScroll } from 'utils/hooks/useResetScroll';
import { useView } from 'utils/hooks/useView';
import { Country } from 'utils/i18n/Country';

const SFlex = styled(Flex)`
  cursor: pointer;
`;

export const getPortfolioData = (small: boolean, showTires: boolean, repairLink: string): Array<PortfolioCardProps> => {
  const tires = {
    bg: 'home/tires.jpg',
    title: <Trans i18nKey={'catalog.tires'}>Tires</Trans>,
    description: <Trans i18nKey={'catalog.tires.description'}>Feel the road as you never did before</Trans>,
    link: ROUTER_TIRES,
    headerColor: 'white',
    index: 0,
    dark: true,
  };

  const universalProducts = {
    bg: 'home/universal-products.jpg',
    title: <Trans i18nKey={'catalog.universal_products'}>Universal products</Trans>,
    description: <Trans i18nKey={'catalog.universal_products.description'}>Find Tuning & Styling products</Trans>,
    link: ROUTER_UNIVERSAL_PRODUCTS,
    headerColor: 'black',
    index: 1,
    dark: false,
  };

  const accessories = {
    bg: 'home/accessories.jpg',
    title: <Trans i18nKey={'catalog.merchandizing'}>Merchandizing</Trans>,
    description: (
      <Trans i18nKey={'catalog.merchandizing.description'}>Explore the Huge Range of Car Merchandizing</Trans>
    ),
    link: '',
    headerColor: 'black',
    index: 2,
    dark: false,
  };

  const electronicPieces = {
    bg: 'home/electronic-pieces.jpg',
    title: <Trans i18nKey={'catalog.electronic_pieces'}>Electronic pieces</Trans>,
    description: <Trans i18nKey={'catalog.electronic_pieces.description'}>Looking for a catch line</Trans>,
    link: repairLink,
    headerColor: 'white',
    index: 3,
    dark: true,
  };

  return small
    ? ([showTires && tires, universalProducts, electronicPieces, accessories].filter(
        Boolean,
      ) as Array<PortfolioCardProps>)
    : ([showTires && tires, universalProducts, accessories, electronicPieces].filter(
        Boolean,
      ) as Array<PortfolioCardProps>);
};

const headerColors: any = {
  black: 'h1_lead',
  white: 'h1_lead_white',
};

export interface PortfolioCardProps {
  bg: string;
  title: ReactNode;
  description: ReactNode;
  link: string;
  index: number;
  headerColor: string;
  dark: boolean;
}

export const PortfolioCard = ({ bg, title, description, index, headerColor, dark, link }: PortfolioCardProps) => {
  const { t } = useTranslation();
  const breakpointSelector = useBreakpointSelector();
  const small = useSmall();
  const repairLink = useSelector(getElectronicPartsRepairLink) ?? '';

  const justify = breakpointSelector(['center', index % 2 === 0 ? 'flex-end' : 'flex-start']);
  const descriptionFont: any = dark ? 'lead_white' : 'lead_dim';
  const background = dark ? 'black' : 'white';

  const fullBreakpointSelector = useBreakpointSelectorFull();
  const width = fullBreakpointSelector({
    xs: 0,
    sm: 691,
    md: 691,
    lg: 691,
    xl: 691,
    xxl: 691 * 2,
  });
  const height = fullBreakpointSelector({
    xs: 0,
    sm: 389,
    md: 389,
    lg: 389,
    xl: 389,
    xxl: 389 * 1.35,
  });

  const isElectronicPiecesLink = link === repairLink;

  return (
    <SFlex justify={justify as FlexJustifyType} onClick={link.length === 0 ? notifyNotImplemented : () => undefined}>
      <Box
        width={width}
        height={small ? 150 : height}
        backgroundImage={bg}
        backgroundImageType={PUBLIC}
        background={background}
        align={'center'}
      >
        <Link to={link} {...(isElectronicPiecesLink && { external: true, newTab: true })}>
          <Box width={'100%'} height={'100%'}>
            <Box height={30} />
            <Text type={headerColors[headerColor]} cursor={'pointer'}>
              {title}
            </Text>
            <Text cursor={'pointer'} type={descriptionFont}>
              {description}
            </Text>
            <br />
            <Text type={'h5_light'} displayStyle={'link'} cursor={'pointer'} hoverUnderLine>
              {t('common.link.learn_more', 'Learn more')}
            </Text>
          </Box>
        </Link>
      </Box>
    </SFlex>
  );
};

const HomePage = (): JSX.Element => {
  const small = useSmall();
  const promotionBanners = useSelector(getPromotionBanners);
  const banners: PromotionBannerCarousel[] = useMemo(() => [...(getData(promotionBanners) ?? [])], [promotionBanners]);
  const bannersWithImage = useMemo(() => (hasData(banners) ? banners.filter((banner) => banner.showInCarousel) : []), [
    banners,
  ]);
  const vehicleSearchHistory = useSelector(getVehicleSearchHistory);
  const country = useSelector(getUserCountry);
  const showTires = Country.canShowTiresByCountry(country);
  const repairLink = useSelector(getElectronicPartsRepairLink) ?? '';

  const portfolioData = getPortfolioData(small, showTires, repairLink);

  useResetScroll();
  useAutomaticFirstHelp(ROUTER_HOME, false);

  return useView([HomepageSm, HomepageLg, HomepageLg], {
    vehicleSearchHistory,
    banners,
    bannersWithImage,
    portfolioData,
  });
};
export default HomePage;
